<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 16:39:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div class="moduleDetail-main-box">
        <el-dialog
        :title="$t('PV.info')"
        :visible="visibleShow"
        class="dialog-lg"
        width="1200px"
        :before-close="handleClose">
        
        <div>
           <el-form ref="form" :model="detailData" label-width="230px" label-position='left'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('PV.manufacturer')" prop="manufacturerID">
                        <template v-for="(item) in manufacturerList">
                            <span v-if="item.manufacturerID==detailData.manufacturerID" :key="item.manufacturerID">{{item.name}}</span>
                        </template>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('PV.module.type')" prop="name">
                        {{detailData.name}}
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.electric-properties')}}</el-divider>
                    <el-form-item :label="$t('PV.rated.power')" prop="pModMPP">
                        {{detailData.pModMPP}}Wp
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.performance-tolerance')" prop="tolerancePModMPP">
                        {{detailData.tolerancePModMPP}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage')" prop="uModMPP">
                        {{detailData.uModMPP}}V
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-current')" prop="iModMPP">
                        {{detailData.iModMPP}}A
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.open-voltage')" prop="uMod0">
                        {{detailData.uMod0}}V
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-voltage')" prop="iModSC">
                        {{detailData.iModSC}}A
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.per-system-voltage')" prop="uModMax">
                        {{detailData.uModMax}}V
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.PV-module-effi.')" prop="etaMod">
                        {{detailData.etaMod||0.0}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.grounding')" prop="isGeneratorGroundRequired">
                        <template v-for="item in groundList">
                            <span v-if="item.value==detailData.isGeneratorGroundRequired" :key="item.value">{{item.name}}</span>
                        </template>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.mechanical-properties')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.number-cells-in-PV')" prop="cellsCount">
                        {{detailData.cellsCount}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.weight')" prop="moduleWeight">
                        {{detailData.moduleWeight}}Kg
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.length')" prop="moduleLength">
                        {{detailData.moduleLength}}mm
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.width')" prop="moduleWidth">
                        {{detailData.moduleWidth}}mm
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item :label="$t('new.PV.management.cell-tech')" prop="materialID">
                        <template v-for="(item) in materialList">
                            <span v-if="item.id==detailData.materialID" :key="item.id">{{item.name}}</span>
                        </template>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.temperature-coefficients')}}</el-divider>
                    <el-form-item label="" prop="tempModUMPPrel" class="th">
                        <span class="span-width">%/°C</span>
                        <span>mV/°C</span>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage')" prop="tempModUMPPrel">
                        <span class="span-width">{{detailData.tempModUMPPrel}}</span>
                        <span>{{detailData.tempModIMPP}}</span>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.open-voltage')" prop="tempModU0rel">
                        <span class="span-width">{{detailData.tempModU0rel}}</span>
                        <span>{{detailData.tempModU0}}</span>
                    </el-form-item>
                    <el-form-item label="" prop="tempModUMPPrel" class="th">
                        <span class="span-width">%/°C</span>
                        <span>mA/°C</span>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-voltage')" prop="tempModISCrel">
                        <span class="span-width">{{detailData.tempModISCrel}}</span>
                        <span>{{detailData.tempModISC}}</span>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.degradation-aging')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.open-circuit-tolerance')">
                        {{detailData.toleranceModU0||0.0}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage-tolerance')">
                        {{detailData.toleranceModUMPP||0.0}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-current-tolerance')">
                        {{detailData.toleranceModIMPP||0.0}}
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-circuit-tolerance')">
                        {{detailData.toleranceModISC||0.0}}
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.comment')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.comment')" prop="comment">
                        {{detailData.comment}}
                    </el-form-item>
                    <el-divider content-position="center">{{$t('import.time')}}</el-divider>
                    <el-form-item :label="$t('import.time')" prop="comment">
                        {{detailData.importTime}}
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        detailData:{}
    },
    data() {
        return {
            manufacturerList:[],
            materialList:[],
            groundList:[{name:this.$t('new.PV.management.ungrounded'),value:"2"},{name:this.$t('new.PV.management.positive-ground'),value:"1"},{name:this.$t('new.PV.management.negative-ground'),value:"-1"}]
        };
    },
    computed: {
        visibleShow(){
            return this.$store.state.sysStore.visibleShow
        }
    },
    watch: {
        detailData:{
            handler(nv,ov){
                if(nv){
                    for(let key in nv){
                        if(key=='tolerancePModMPP'||key=='etaMod'||key=='toleranceModU0'||key=='toleranceModUMPP'||key=='toleranceModIMPP'||key=='toleranceModISC'){
                            this.detailData[key]=this.detailData[key]!=null&&this.detailData[key]!=''?this.detailData[key]+'%':'-'
                        }else{
                            this.detailData[key]=nv[key]||'-'
                        }
                    }

                }
            }
        }
    },
    created() {

    },
    mounted() {
        this.systemTManufacturerGetList()
        this.systemTMaterialGetList()
    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleShow',false)
        },
        /*获取供应商*/
        async systemTManufacturerGetList(){
            let res = await this.API.systemTManufacturerGetList()
            this.manufacturerList = res
        },

        /*获取晶片工艺*/
        async systemTMaterialGetList(){
            let res = await this.API.systemTMaterialGetList()
            this.materialList = res;
        }
    },
};
</script>

<style lang="less">
.moduleDetail-main-box{
    .modal-box-flex{
    display: flex;
    justify-content: space-between;
    padding: 5px;
        div:nth-child(1){
        flex: 2
        }
        div:nth-child(2){
        flex: 1
        }
    }
    .el-dialog .el-form-item {
        margin-bottom: 0;
        margin-right: 0;
    }
    .span-width{
        display: inline-block;
        width:180px;
    }
    .el-form-item__content span{
        width: 100px;
    }
    .th{
        span{
            font-weight: bold;
            line-height: 15px;
            font-size: 12px;
        }
    }
}
</style>
