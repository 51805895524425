<!--
 * @Author: your name
 * @Date: 2020-11-25 09:06:45
 * @LastEditTime: 2021-01-13 18:43:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\steps.vue
-->
<template>
    <div class="steps-main-box">
        <template v-for='(item,index) in menus' >
            <template v-if="item.show">
                <div :key='item.id'  class="sub-menus-box box" :class="active == item.id?'color-active':''" :style="item.next?'color: #FFAF40;':''" @click='changeMenus(item)'>
                    <i :class="item.icon" style="margin-bottom:5px;"></i>
                    <span style="padding-bottom:5px">{{item.text}}</span>
                </div>
                <div v-if='index == menus.length-1 ? false:true' :key='item.id*10'><i class="el-icon-arrow-right"></i></div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        next:{
            type:Boolean,
            default:false
        },
        menus:{
            type:Array,
            default:()=>{return []}
        },
        all:{
            type:Boolean,
            default:false 
        },
        active:{
            type:Number,
            default:1,
        },
        projectData: {
            type: Object,
            default:()=>{
                return {}
            }
        },
        data: {
            type: Object,
            default:()=>{
                return {}
            }
        },
    },
    data() {
        return {
            // active:1
            itemData:"",
            proId:this.$route.query.id,
            deleteIndex:[]
        };
    },
    computed: {

    },
    watch: {
        next:{
            handler(nv,ov){
                //  this.menus[this.active].next = nv
                 this.menus.forEach((nextItem,index)=>{
                    if (nextItem.id == this.active) {
                        nextItem.next=nv
                    }
                })
            },
            deep:true,
            immediate:true
        },
        active:{
            handler(nv,ov){
                // if (ov == 2 ) {
                //     this.data.forms.forEach((item)=>{
                //         if (item.manufacturerId == ''||item.moduleId == '') {
                //             console.log(112233);
                //             this.active = 2
                //             this.open()
                //         }
                //     })
                // }
            },
            deep:true,
            immediate:true
        },
        // all:{
        //     handler(nv,ov){
        //         if(nv){
        //             this.menus.forEach(item =>{
        //                 item.next = true
        //             })
        //         }
        //         if(nv == false){
        //             this.menus.forEach(item =>{
        //                 if(item.id>this.active){
        //                     item.next = false
        //                 }
                        
        //             })
        //         }
        //     },
        //     deep:true,
        //     immediate:true
        // }
    },
    created() {

    },
    mounted() {

    },
    updated(){
    },
    methods: {
        changeMenus(item){
            if(!item.next){
                return
            }
            this.deleteIndex = []
            this.itemData = item
            if (this.active == 2 ) {
                let Incomplete = false
                this.data.forms.forEach((itemPv,index)=>{
                    if (itemPv.manufacturerId == ''||itemPv.moduleId == ''||itemPv.moduleId == null) {
                        this.deleteIndex.unshift(index)
                        this.open(item)
                        Incomplete = true
                    }
                })
                if (Incomplete) return
            }
            console.log(item,"item1");
            this.$emit('update:active',item.id)
            if( item.id != 8){
                this.menus.forEach((nextItem)=>{
                    if (nextItem.id == item.id) {
                        nextItem.next=item.next
                    }
                })
            }
            this.$emit('changeClick',item)
        },
        open(item) {
            this.$confirm(this.$t('PV-tipDetail'), '', {
                confirmButtonText: this.$t('common.button.ok'),
                cancelButtonText: this.$t('common.button.cancel'),
            }).then((item) => {
                console.log(item,"item");
                this.$emit('update:active',this.itemData.id)
                this.deleteIndex.forEach((item)=>{
                    this.deleteMoudle(item)
                })
                
            }).catch(() => {
                this.$emit('update:active',2)       
            });
        },
         async deleteMoudle(index){
            console.log(index);
            if(this.data.forms.length >1){
                let res = await this.API.deletePvArray({
                    projectId: this.proId,
                    pvArrayId:index
                })
                // this.data.forms.splice(index,1);
                this.$emit('noticeUpload',res)
            }else{
            }
        },
    },
};
</script>

<style  lang="less" scoped>
.steps-main-box{
    width: 80%;
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    font-size: 18px;
    color: #bbb;
    .sub-menus-box{
        display: flex;
        flex-direction:column;
        text-align: center;
        cursor:pointer;
        font-size: 15px;
        .iconfont{
            font-size:26px;
        }
    }
    .color-active{
        color: #FFAF40 !important;
        border-bottom: 2px solid #FFAF40;
        transition: .2s;
        transform: scale(1);
    }
    .box:hover{
        color: #FFAF40;
        transform: scale(1.2);
    }
}
</style>
