<!--
 * @Author: your name
 * @Date: 2020-11-26 09:52:24
 * @LastEditTime: 2021-03-25 17:27:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterData.vue
-->
<template>
    <div class="container-return">
        <div class="left-sub-box">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    {{$t('other')}}
                </div>
                <div>
                    <el-form ref="form" :model="form" label-width="200px" label-position='left'>
                        <el-row :gutter="24">
                            <el-col>
                                <el-form-item>
                                    <div slot="label"><el-checkbox :true-label="1" :false-label="0" v-model="form.isReturnInvestment">{{$t('project.data.cal-return-investment')}}</el-checkbox></div>
                                </el-form-item>
                            </el-col>
                            <el-col v-show="form.isReturnInvestment==true">
                                <el-form-item :label="$t('project.data.currency')" prop="currency">
                                    <el-select v-model="form.currency" :placeholder="$t('common.msg.select.please')" @change="currencyChange">
                                        <el-option
                                        v-for="item in currencyList"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.data.total-amount')" prop="totalAmount">
                                    <el-input @blur="saveReturnToCache" v-model="form.totalAmount" @keyup.native="oninput('totalAmount')" :disabled="form.isDetailAmount==1">
                                        <template slot="append">{{currencyUnit}}</template>
                                    </el-input>
                                    <el-checkbox @change="saveReturnToCache" :true-label="1" :false-label="0" v-model="form.isDetailAmount" class="marginleft_10">{{$t('project.data.is-detail-amount')}}</el-checkbox>
                                </el-form-item>
                                <el-row :gutter="24" class="box-bg" v-show="form.isDetailAmount==1">
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.component-cost')" prop="componentCost" label-width="150px" @keyup.native="oninput('componentCost')">
                                            <el-input @blur="saveReturnToCache"  v-model="form.componentCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.inverter-cost')" prop="inverterCost" label-width="150px" @keyup.native="oninput('inverterCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.inverterCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.distributor-cost')" prop="distributorCost" label-width="150px" @keyup.native="oninput('distributorCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.distributorCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.transformer-cost')" prop="transformerCost" label-width="150px" @keyup.native="oninput('transformerCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.transformerCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.support-cost')" prop="supportCost" label-width="150px" @keyup.native="oninput('supportCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.supportCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.traffic-cost')" prop="trafficCost" label-width="150px" @keyup.native="oninput('trafficCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.trafficCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.repair-cost')" prop="repairCost" label-width="150px" @keyup.native="oninput('repairCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.repairCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.install-cost')" prop="installCost" label-width="150px" @keyup.native="oninput('installCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.installCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.battery-cost')" prop="batteryCost" label-width="150px" @keyup.native="oninput('batteryCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.batteryCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.pressurizer-cost')" prop="pressurizerCost" label-width="150px" @keyup.native="oninput('pressurizerCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.pressurizerCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.pump-cost')" prop="pumpCost" label-width="150px" @keyup.native="oninput('pumpCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.pumpCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('project.data.other-cost')" prop="otherCost" label-width="150px" @keyup.native="oninput('otherCost')">
                                            <el-input @blur="saveReturnToCache" v-model="form.otherCost">
                                                <template slot="append">{{currencyUnit}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item>
                                    <div slot="label">
                                        <el-checkbox @change="saveReturnToCache" :true-label="1" :false-label="0" v-model="form.isLoan" >{{$t('project.data.is-loan')}}</el-checkbox>
                                    </div>
                                </el-form-item>
                                <el-row :gutter="24" class="box-bg" v-if="form.isLoan==true">
                                    <el-col :span="8">
                                        <el-form-item :label="$t('project.data.loan-ratio')" prop="loanRatio" label-width="auto" >
                                            <el-input @blur="saveReturnToCache" v-model="form.loanRatio" @keyup.native="oninput('loanRatio',2)">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item :label="$t('project.data.lending-rate')" prop="lendingRate" label-width="auto">
                                            <el-input @blur="saveReturnToCache" v-model="form.lendingRate" @keyup.native="oninput('lendingRate')">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item :label="$t('project.data.loan-term')" prop="loanTerm" label-width="auto">
                                            <el-input @blur="saveReturnToCache" v-model="form.loanTerm" @keyup.native="oninput('loanTerm')">
                                                <template slot="append">{{$t('common.msg.year')}}</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item :label="$t('project.data.self-use-ratio')" prop="selfUseRatio">
                                    <el-input @blur="saveReturnToCache" v-model="form.selfUseRatio" @keyup.native="oninput('selfUseRatio',3)">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$t('project.data.electricity-price')" prop="electricityPrice">
                                    <el-input @blur="saveReturnToCache" v-model="form.electricityPrice" @keyup.native="oninput('electricityPrice')">
                                        <template slot="append">{{currencyUnit}}/ kWh</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$t('project.data.electricity-subsidy')" prop="electricitySubsidy">
                                    <el-input @blur="saveReturnToCache" v-model="form.electricitySubsidy" @keyup.native="oninput('electricitySubsidy')">
                                        <template slot="append">{{currencyUnit}}/ kWh</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="ROI">
                                    {{totalReturnRedio}} <span v-show="totalReturnRedio">%</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
        </div>
        <div class="right-sub-box">
            <rightReport @clickNext="clickNext"></rightReport>
        </div>
    </div>
</template>

<script>
import rightReport from './rightReport'
export default {
    components: {
        rightReport
    },
    props: {
        form:{
            type: Object,
            default () {
                return {}
            }
        },
        active:{
            type:Number,
            default:5,
        },
        next:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {
            currencyUnit:'¥',
            currencyList:[],
            cost:{
                componentCost:0,
                inverterCost:0,
                distributorCost:0,
                transformerCost:0,
                supportCost:0,
                trafficCost:0,
                repairCost:0,
                installCost:0,
                batteryCost:0,
                pressurizerCost:0,
                pumpCost:0,
                otherCost:0,
            },
            totalReturnRedio:""
        };
    },
    computed: {

    },
    watch: {
        active:{
            handler(nv,ov){
                if(ov == 7 &&nv != 7){
                    this.saveReturnToCache()
                    this.tprojectSave()
                }
                if(ov != 7 &&nv == 7){
                    this.saveReturnToCache()
                    this.$emit('changeNext',true)
                }
            }
        },
        form:{
            handler(nv,ov){
                let totalAmount=0;
                if(!(this.form && this.form.totalAmount)){
                    this.$set(this.form,'totalAmount',totalAmount || 0)
                }  
                if(nv){
                    if(nv.isDetailAmount==1){
                        for(let key in this.cost){
                            this.$set(this.form,key,nv[key]||0)
                            totalAmount+=parseFloat(this.form[key])
                        }
                        // console.log(totalAmount,'total')
                        this.$set(this.form,'totalAmount',totalAmount || 0)
                    }
                    if(nv.currency){
                        let item = this.currencyList.find(item => item.code == nv.currency);
                        this.currencyUnit = item.value.split("-")[0]
                    }else{
                        this.currencyUnit = ((this.currencyList[0]||{}).value||'').split("-")[0]
                    }
                }else {
                    this.$set(this.form,'totalAmount',totalAmount || 0)
                }
            },
            deep:true,
            immediate:true
        }
    },
    created() {

    },
    mounted() {
        this.systemTDictionary()
    },
    methods: {
        // 每个步骤自动保存
        async tprojectSave(){
            let status = parseInt(localStorage.getItem('statusF'))
            if (status < 7) {
                status = 7
                localStorage.setItem("statusF",7)
            }
            let res = await this.API.tprojectSave({
                proId:this.$route.query.id,
                status
            })
        },
        //缓存
        async saveReturnToCache(){
            this.form.id=this.form.id||''
            this.form.sid=this.form.sid||''
            this.form.proId = this.$route.query.id
            let res = await this.API.saveReturnToCache(
                this.form
            )
            this.totalReturnRedio =(res.object||{}).totalReturnRedio
        },

        /*货币改变*/
        currencyChange(value){
            let item = this.currencyList.find(item => item.code == value);
            this.currencyUnit = item.value.split("-")[0]
            this.saveReturnToCache()
        },

        /*获取货币字典数据*/
        async systemTDictionary(){
            let res = await this.API.systemTDictionary({
                dictCode: 'RETURN_INVESTMENT'
            })
            this.currencyList = res
        },
    	oninput(num,max) {
            var str = this.form[num].toString()
            // debugger;
            var len1 = str.substr(0, 1)
            var len2 = str.substr(1, 1)
            //如果第一位是0，第二位不是点，就用数字把点替换掉
            if (str.length > 1 && len1 == 0 && len2 != ".") {
                str = str.substr(1, 1)
            }
            //第一位不能是.
            if (len1 == ".") {
                str = ""
            }
            //限制只能输入一个小数点
            if (str.indexOf(".") != -1) {
                var str_ = str.substr(str.indexOf(".") + 1)
                if (str_.indexOf(".") != -1) {
                    str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
                }
            }
            //不能超过设置的最大值
            let _max=max||9
            if (str.indexOf(".") != -1) {
                var _str=str.split(".")[0]
                str=_str.substr(0,_max)+'.'+str.split(".")[1]
            }else{
                str=str.substr(0,_max)
            }
            //正则替换
            str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
            str = str.replace(/\.\d\d\d$/,'') // 小数点后只能输两位

            // this.form[num]=str
            this.$set(this.form,num,str)
            return
        },
         // 点击next
        clickNext(){
              if(this.next) {
                this.$emit('nextMenus',this.active+1)
                this.$emit('changeNext',false)
              } 
            
        }    
    },
};
</script>

<style  lang="less" >
.container-return{
    display: flex;
    .el-card__header{
        background-color: #ddd;
        cursor:pointer;
    }
    
    .left-sub-box{
        flex: 9;
        padding: 10px;
        .box-card{
            .el-card__header:hover{
                color: rgb(44, 154, 197);
            }
        }
    }
    .right-sub-box{
        flex: 3;
        padding: 10px;
    }
    .img-and-table-box{
        display: flex;
        .img-box{
            flex: 1;
        }
        .table-box{
            flex: 7;
        }
    }
    .margin-top20{
        margin-top:20px;
    }
    
    .inline{
        .el-input,
        .el-select{
            display: inline-block;
            width: 200px;
            margin-right: 10px;
            &.small{
                width: 100px;
                &>.el-input{
                    width: 100px;
                }
            }
            .el-form-item--small .el-form-item__error {
                left: 208px;
            }
        }
        .input-title{
            height: 80px;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .el-select,.el-input {
        width: 150px;
    }
    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }
    .el-input-group__append, .el-input-group__prepend{
        padding: 0 5px;
    }
    .box-bg{
        padding: 10px 20px;
        margin-bottom: 10px;
        background: #f1f1f1;
        box-sizing: border-box;
        border-radius: 5px;
    }
}
</style>
