<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 14:51:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div>
        <el-dialog
        :title="$t('project.Equipment-detail')"
        :visible="visibleInfo"
        width="40%"
        :before-close="handleClose">
        
        <div class="inverterDetail-main-box">
            <el-row   style="margin-bottom:10px">
                <el-col :span="12">
                    dryContact : {{adapterDetailData.dryContact}}
                </el-col>
                <!-- <el-col :span="14" style="text-align:right">{{item.paramVal}}</el-col> -->
                <el-col :span="12">
                    workMode : {{adapterDetailData.workMode}}
                </el-col>
            </el-row>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        adapterDetailData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {

        };
    },
    computed: {
        visibleInfo(){
            // console.log(this.$store.state.sysStore.visibleAdapterInfo);
            return this.$store.state.sysStore.visibleAdapterInfo
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            console.log(11);
            this.$store.commit('setVisibleAdapterInfo',false)
            console.log(this.$store.state.sysStore.visibleAdapterInfo);
        },
    },
};
</script>

<style  lang="less" >
.inverterDetail-main-box{
    .modal-box-flex{
        display: flex;
        // justify-content: space-between;
        padding: 5px;
            div:nth-child(1){
            flex: 2
            }
            div:nth-child(2){
            flex: 1
            }
    }
}
</style>
