<template>
  <div class="screenContain">
    <div class="screen_inverter">
          <div class="screen_top">
            <span>{{$t('project.all')}} <i class="el-icon-arrow-right"></i></span>
            <span class="selection_Criteria" v-show="HybridFlag"> Hybrid <i class="el-icon-close" @click="clickType(3)"></i></span>
            <span class="selection_Criteria" v-show="gridFlag"> On-grid <i class="el-icon-close" @click="clickType(4)"></i></span>
            <span class="selection_Criteria" v-show="SinglePhase"> Phase 1 <i class="el-icon-close" @click="clickPhase(2)"></i></span>
            <span class="selection_Criteria" v-show="ThreePhase"> Phase 3 <i class="el-icon-close" @click="clickPhase(4)"></i></span>
            <el-button icon="el-icon-back" v-if="!firstFlag" plain style="margin:0 0 0 auto" @click="backInverter">{{$t('return')}}</el-button>
          </div>
          <div class="filter_Criteria">
              <div class="screen-item">
                  <div class="left">Type</div>
                  <div class="right">
                    <span v-if="IsStorageflag" @click="clickType(1)" :class="HybridFlag?'check':'nocheck'">Hybrid</span>
                    <span v-else class="prohibit">Hybrid</span>
                    <span v-if="jp_countryID!=='34'&&flag_regionID!=='4'" @click="clickType(2)" :class="gridFlag?'check':'nocheck'">On-grid</span>
                  </div>
              </div>
              <div class="screen-item">
                  <div class="left">Phase</div>
                  <div class="right">
                    <span  @click="clickPhase(1)" :class="SinglePhase?'check':'nocheck'">1</span>
                    <span v-if="Phaseflag&&jp_countryID!=='34'&&flag_regionID!=='4'" @click="clickPhase(3)" :class="ThreePhase?'check':'nocheck'">3</span>
                    <span v-else-if="jp_countryID!=='34'&&flag_regionID!=='4'" class="prohibit">3</span>
                  </div>
              </div>
              <div class="screen-item">
                <div class="left">Power(KW)</div>
                <div class="right">
                    <el-checkbox-group v-model="powerSel" :max="1"  @change="changePower">
                      <el-checkbox v-for="powerItem in powerList" :label="powerItem.type" :key="powerItem.type">{{powerItem.power}}</el-checkbox>
                    </el-checkbox-group>
                    <div style="margin-left:30px">
                        <el-input  v-model="form.powerMin" :disabled="powerDis" @clear="getInverterListData" clearable style="width:80px;" @blur="powerBlur(1)" @input="powerMinInput" :min="0" :max="300" ></el-input>
                         <span style="width:40px;margin:0px 5px"> ~ </span>
                        <el-input  v-model="form.powerMax" :disabled="powerDis" @clear="getInverterListData" clearable style="width:80px;" @blur="powerBlur(2)" @input="value => form.powerMax=value.replace(/[^(0-9)]/g,'')" :min="1" :max="300"  ></el-input>
                    </div>
                    
                </div>
              </div>
              <div class="screen-item">
                <div class="left2">Input current DC(A)
                  <el-tooltip class="item" effect="dark" content="Max.current for single MPPT. Some inverter may hava multiple MPPT with different current values." placement="top" >
                    <i class="el-icon-warning-outline" style="color: #cbcbcb; font-size: 20px;" ></i> 
                  </el-tooltip>
                </div>
                <div class="right">
                    <el-checkbox-group v-model="current"   @change="changeCurrent" style="white-space:nowrap">
                      <el-checkbox v-for="current in currentList" :label="current" :key="current" >{{current}}</el-checkbox>
                    </el-checkbox-group>
                </div>
              </div>
              <div class="screen-item">
                <div class="left2">Optimal power rated ratio(%)</div>
                <div class="right">
                    <el-checkbox-group v-model="ratio"   @change="changeRatio">
                      <el-checkbox v-for="ratioItem in ratioList" :label="ratioItem" :key="ratioItem">{{ratioItem}}</el-checkbox>
                    </el-checkbox-group>
                    <div style="margin-left:30px">
                        <el-input  v-model="ratioInp" :disabled="ratioDis" @clear="getInverterListData" clearable style="width:80px;" :min="100" :max="300" @blur="ratioBlur" @input="value => ratioInp=value.replace(/[^(0-9)]/g,'')" ></el-input>
                          <!-- ~ 
                        <el-input type="number"  v-model="number" style="width:80px;" :min="100" :max="300" placeholder="单行输入"></el-input> -->
                    </div>
                </div>
              </div>
          </div>
    </div>
    

    <!-- 逆变器列表 -->
    <div class="inverterList">
        <div v-for="item in inverterList" :key="item.invID" class="inverterList_item" @click="selectInverter(item)">
          <!-- <el-badge value="new" class="item"> -->
            <span class="PopularHot" v-if="item.recommend == 1">Hot</span>
            <img :src="item.url" alt="" width="110px" height="120px" style="max-width:150px;">
          <!-- </el-badge> -->
          <span>{{item.name}}</span>
        </div>
        <div v-if="moreBtn" style="fontSize:22px;color:#101010;width:140px;height:136px;cursor:pointer" @click="clickMore" class="inverterList_item">......</div>
        <div v-if="noData" style="margin:0 auto;height:100px;line-height:100px;color:#C0C4CC"> No data </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  props:{
    active:{
        type:Number,
        default:3,
    },
    InvId:{
        type:String,
        default:'',
    },
    IsStorageInv:{
        type:Boolean,
        default:'',
    },
    firstFlag:{
        type:Boolean,
        default:'',
    },
    projectData: {
        type: Object,
        default:()=>{
            return {}
        }
    },
  },
  data() {
    return {
      radio1:'',
      powerSel:[],
      powerList:[{power:'P ≤ 5',type:1},{power:'5 < P ≤ 15',type:2},{power:'15 < P ≤ 30',type:3},{power:'30 < P ≤ 150',type:4}],
      current:[],
      currentList:[],
      ratio:[],
      ratioList:[],
      imgUrlBase:require('../../../assets/img/nbq.jpg'),
      inverterList:[],    //逆变器数据
      HybridFlag:false,
      gridFlag:false,
      SinglePhase:false,
      ThreePhase:false,
      number:'',
      inverterAllList:[],
      isRefreshBool:true,
      moreBtn:true,
      form:{
        isStorage:'',
        phase:"",
        power:"",
        inputCurrent:"",
        ratio:[],
        powerMax:"",
        powerMin:""
      },
      IsStorageflag:true,
      noData:false,
      powerDis:false,
      ratioDis:false,
      Phaseflag:true,
      ratioInp:"",
      imgList:[],
      jp_countryID:'',
      flag_regionID:'',
    }
  },
  watch:{
    active:{
        handler(nv,ov){
            if(ov != 3 &&nv == 3){
              
            }
            if (ov == 3 &&nv != 3) {
              
              
            }
        },
        deep:true,
        immediate:true,
    },
    imgList:{
      handler(nv){
        nv.forEach((imgItem)=>{
          this.inverterList.forEach((item)=>{
            if (imgItem.invID == item.invID) {
              this.$set(item,'url',imgItem.url)
            }
          })
        })
      },
      immediate:true,
      deep:true
    },
    'projectData.phase':{
      handler(nv,ov){
        this.defaultSreen()
      },
      immediate:true,
    },
    'projectData.batteryRequired':{
      handler(nv,ov){
        this.defaultSreen()
      },
      immediate:true,
    },
  },
  computed(){
    
  },
  mounted(){
    this.tdictionaryGetDictionaryBatch()
    // this.$emit('addInverterData')
    window.addEventListener("scroll", this.isRefresh, true);
    this.defaultSreen()
    //初始化查询条件
    // if (this.InvId) {
    if (0) {
      console.log(4);
      let data = sessionStorage.getItem("screen"+this.InvId)
      data = JSON.parse(data)
      this.form.isStorage = data.isStorage||''
      // this.form.ratio = data.ratio||''
      this.form.inputCurrent = data.inputCurrent||''
      // this.form.powerMin = data.powerMin||''
      // this.form.powerMax = data.powerMax||''
      this.form.phase = data.phase||''
      this.HybridFlag=data.HybridFlag||false
      this.gridFlag=data.gridFlag||false
      this.SinglePhase=data.SinglePhase||false
      this.ThreePhase=data.ThreePhase||false
      this.current=data.current||[]
      this.ratio=data.ratio||[]
      this.powerSel=data.powerSel||[]
    }
    console.log(this.IsStorageInv,this.firstFlag,"IsStorageFlag");
    if (this.IsStorageInv) {
      console.log(3);
      this.IsStorageflag=true
    }else if (this.IsStorageInv == false&&!this.firstFlag){
      console.log(2);
        this.IsStorageflag=false
        this.HybridFlag=false
        this.gridFlag=true
        this.form.isStorage = 0
    }
    if (this.firstFlag) {
      // this.IsStorageflag=true
      console.log(1);
      this.defaultSreen()
    }
      this.getInverterListData()
      this.jp_countryID = localStorage.getItem('jp_countryID')
      this.flag_regionID = localStorage.getItem('flag_regionID')
      console.log(this.flag_regionID);
      console.log(this.jp_countryID);
    
  },
  methods:{
    backInverter(){
      this.$emit("backInv")
    },
     async tdictionaryGetDictionaryBatch() {
        let res = await this.API.tdictionaryGetDictionaryBatch({
            dictCodes: [
                "INPUT_CURRENT_DC",
                "RATED_RATIO"
            ],
        });
        this.currentList=[]
        this.ratioList = []
        res.INPUT_CURRENT_DC.forEach((item)=>{
          this.currentList.push(item.code)
        })
        res.RATED_RATIO.forEach((item)=>{
          this.ratioList.push(item.code)
        })
    },
    powerMinInput(value){
      this.form.powerMin=value.replace(/[^(0-9)]/g,'')
      if (this.form.powerMin<0) {
        this.form.powerMin = 0
      }else if (this.form.powerMin > 300) {
        this.form.powerMin = 300
      }
    },
    powerBlur(flag){
      // console.log(this.form.powerMax ,this.form.powerMin);
      if (this.form.powerMax == '' &&this.form.powerMin == '') {
        return
      }
      // console.log((flag == 2&&this.form.powerMin != ''),"flag");
      // console.log(parseInt(this.form.powerMin) > parseInt(this.form.powerMax),"flag");
      if (flag == 1&&this.form.powerMin != '') {
        if (parseInt(this.form.powerMin) > parseInt(this.form.powerMax)) {
          this.form.powerMin = ''
          this.$message.warning(this.$t('Power-range-min'));
          return
        }
        this.getInverterListData()
      }
      if (flag == 2&&this.form.powerMax != '') {
        if (parseInt(this.form.powerMin) > parseInt(this.form.powerMax)) {
            this.form.powerMax = ''
            this.$message.warning(this.$t('Power-range-max'));
            return
        }
        this.getInverterListData()
      }
      
    },
    ratioInput(value){
        
    },
    ratioBlur(){
      if (this.ratioInp == '') {
        this.ratioInp = ''
        return
      }else if (this.ratioInp< 100) {
          this.ratioInp = 100
        }else if (this.ratioInp > 300) {
          this.ratioInp = 300
        }
      
       this.getInverterListData()
    },
    defaultSreen(){
      if (this.projectData.phase == 1) {
          this.ThreePhase = true
          this.SinglePhase =true
          this.Phaseflag = true
          this.form.phase = 4
        }else if (this.projectData.phase == 0) {
          this.ThreePhase = false
          this.SinglePhase =true
          this.Phaseflag = false
          this.form.phase = 1
        }
        if (this.projectData.batteryRequired == 1) {
          this.HybridFlag = true
          this.gridFlag = false
          this.form.isStorage = 1
        }else{
          this.HybridFlag = false
          this.gridFlag = true
          this.form.isStorage = 0
        }
        if (this.firstFlag) {
          this.getInverterListData()
        }
    },
    /*获取逆变器数据*/
    // queryType 0:默认查询 1:查询更多
    async getInverterListData(queryType=0) {
      let forms = JSON.parse(JSON.stringify(this.form))
      let flag = (forms.ratio||[]).some((item)=> {return item == this.ratioInp})
        if (this.ratioInp&&!flag) {
          forms.ratio.push(this.ratioInp)
        }
        let res = await this.API.systemTinverterGetList({
            ...forms,
            projectId:this.$route.query.id,
            queryType,
            regionID:this.flag_regionID,
            countryID:this.jp_countryID,
        })
        if (res.code == 500) {
          this.inverterAllList = []
          this.inverterList = []
          return
        }
        if (queryType == 0) {
          this.moreBtn = true
          this.noData = false
          this.getInverterListMore(res)
          if (!res.length) {
            this.getInverterListData(1)
          }
        }else if (queryType == 1) {
          this.moreBtn = false
          if (!res.length) {
            this.noData = true
          }else{
            this.noData = false
          }
        }
        if (!res) {
          this.inverterAllList = []
          this.inverterList = []
        }
        this.inverterAllList = res
        this.inverterList = this.inverterAllList
        this.inverterList.forEach(item=>{
           this.returnImgUrl(item)
        })
    },
     async getInverterListMore(data) {
      let forms = JSON.parse(JSON.stringify(this.form))
      let flagV = (forms.ratio||[]).some((item)=> {return item == this.ratioInp})
        if (this.ratioInp&&!flagV) {
          forms.ratio.push(this.ratioInp)
        }
        let res = await this.API.systemTinverterGetList({
            ...forms,
            projectId:this.$route.query.id,
            queryType:1,
            regionID:this.flag_regionID,
            countryID:this.jp_countryID,
        })
        let flag
        res.forEach((itemMore)=>{
          data.forEach((item)=>{
            if (itemMore.invID == item.invID) {
              flag = false
            }else{
              flag = true
            }
          })
        })
        if (res.length == data.length&&!flag) {
          this.moreBtn = false
        }
     },
    // 点击储能机/并网机
    clickType(flag){
      if (flag ==1 ) {
        this.HybridFlag = !this.HybridFlag
        this.gridFlag = false
        console.log(this.HybridFlag,"this.HybridFlag");
        if (this.HybridFlag) {
          this.form.isStorage = 1
        }else {
          this.form.isStorage = ''
        }
      }else if (flag ==2) {
        this.gridFlag= !this.gridFlag
        this.HybridFlag=false
        if (this.gridFlag) {
          this.form.isStorage = 0
        }else{
          this.form.isStorage = ''
        }
      }else if (flag ==3) {
        this.HybridFlag = !this.HybridFlag
        this.HybridFlag=false
        this.form.isStorage = ''
      }else if (flag ==4) {
        this.gridFlag= !this.gridFlag
        this.gridFlag=false
        this.form.isStorage = ''
      }
      this.getInverterListData()
    },
    // 选择单三相
    clickPhase(flag){
      if (flag == 1) {
        this.SinglePhase= !this.SinglePhase
        console.log(!this.SinglePhase,this.ThreePhase);
        if (!this.SinglePhase&&this.ThreePhase) {
            this.ThreePhase= true
            this.SinglePhase=false
            this.form.phase = 3
        }else{
             this.ThreePhase=false
              if (this.SinglePhase) {
                this.form.phase = 1
              }else{
                this.form.phase = ''
              }
        }
      }else if(flag == 3){
        this.ThreePhase= !this.ThreePhase
        if (!this.ThreePhase&&this.SinglePhase) {
            this.ThreePhase= false
            this.SinglePhase=true
            this.form.phase = 1
        }else{
            this.SinglePhase=false
            if (this.ThreePhase) {
              this.form.phase = 3
            }else{
              this.form.phase = ''
            }
        }
        
      }else if (flag == 2) {
        this.SinglePhase =false
        if (!this.SinglePhase&&this.ThreePhase) {
          this.ThreePhase= true
          this.SinglePhase=false
          this.form.phase = 3
        }else{
            this.form.phase = ''
        }
      }else if (flag == 4) {
        this.ThreePhase =false
        if (!this.ThreePhase&&this.SinglePhase) {
            this.ThreePhase= false
            this.SinglePhase=true
            this.form.phase = 1
        }else{
            this.form.phase = ''
        }
       
      }
      this.getInverterListData()
    },
    // 选择power
    changePower(item){
      console.log(item,"changePower");
      if (!item[0]) {
        this.powerDis = false
      }else{
        this.powerDis = true
      }
      if (item[0] == 1) {
        this.form.power = '0-5'
      }else if (item[0] == 2) {
        this.form.power = '6-15'
      }
      else if (item[0] == 3) {
        this.form.power = '16-30'
      }else if (item[0] == 4) {
        this.form.power = '31-150'
      }else{
        this.form.power = ''
      }
      this.getInverterListData()

    },
    // 选择电流
    changeCurrent(item){
      // console.log(item,"item");
       this.form.inputCurrent = item.join()
       this.getInverterListData()
    },
    // 选择额定功率比
    changeRatio(item){
      console.log(item,"item");
      // if (!item[0]) {
      //   this.ratioDis = false
      // }else{
      //   this.ratioDis = true
      // }
      this.form.ratio = item
       this.getInverterListData()
    },
    // 选择筛选出的逆变器
    selectInverter(item){
      let data = Object.assign(this.form)
      data.HybridFlag=this.HybridFlag
      data.gridFlag=this.gridFlag
      data.SinglePhase=this.SinglePhase
      data.ThreePhase=this.ThreePhase
      data.current=this.current
      data.ratio=this.ratio
      data.IsStorage = item.IsStorage
      data.powerSel=this.powerSel
      data = JSON.stringify(data)
      sessionStorage.setItem("screen"+item.invID,data)
      if (this.InvId) {
        item.isModify = true
      }
      this.$emit('screenInverter',item)
    },
    // 请求图片
    async returnImgUrl(item){
      // console.log(item);
      let imgFlag = this.imgList.some((itemImg)=>{
        return itemImg.invID == item.invID
      })
      // console.log(imgFlag,"imgFlag");
      if(item.pictureUrl&&!imgFlag){
        this.imgList.push({
          url:'',
          invID:item.invID
        })
        let res= await this.API.systemTuserGetLogo({
            fileName:item.pictureUrl
        })
        if(res){
            let url ='data:image/gif;base64,' + res
            this.$set(item,'url',url)
            this.imgList.forEach((imgItem)=>{
              if (imgItem.invID == item.invID) {
                this.$set(imgItem,'url',url)
              }
            })
            // this.imgList.push({
            //   url:url,
            //   invID:item.invID
            // })
        }else{
            let url = require('../../../assets/img/nbq.jpg')
            this.$set(item,'url',url)
            this.imgList.push({
              url:url,
              invID:item.invID
            })
        }
      }else{
         if (imgFlag) {
          this.imgList.forEach((imgItem)=>{
            if (imgItem.invID == item.invID) {
              this.$set(item,'url',imgItem.url)
            }
          })
        }else{
            let url = require('../../../assets/img/nbq.jpg')
            this.$set(item,'url',url)
            this.imgList.push({
                url:url,
                invID:item.invID
              })
        }
          
      }
    },
    isRefresh(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      // console.log(scrollTop + windowHeight , scrollHeight -10,this.isRefreshBool);
      if(scrollTop + windowHeight >= scrollHeight - 100 && this.isRefreshBool) {
        // false防止refresh()加载数据函数多次触发
        this.isRefreshBool = false;
        this.refresh();
      }
    },
    refresh(){
      this.isRefreshBool = true;
      let arr = this.inverterAllList.slice(this.inverterList.length,this.inverterList.length+10)
      arr.forEach((item)=>{
        this.returnImgUrl(item)
      })
      this.inverterList = this.inverterList.concat(arr)
      // document.documentElement.scrollTop =0 
    },

    // 点击...获取更多
    clickMore(){
      this.getInverterListData(1)
      this.moreBtn = false
    }
  }
}
</script>

<style lang="less" scoped>
.screenContain{
  margin: -34px 0 0 -10px;
  border: 1px solid #cbcbcb;
  .screen_inverter{
    border-bottom: 2px solid #c9c9c9;
    padding: 5px 15px;
  }
  .screen_top{
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 28px;
    .selection_Criteria{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80px;
      color: #bbb;
      font-size: 14px;
      text-align: left;
      padding: 6px 5px 6px 0;
      background-color: #f0f2f5;
      border-radius: 5px;
      margin-right: 10px;
      i{
        color: #eaedf0;
        font-size: 12px;
        text-align: right;
        box-sizing: border-box;
        padding: 2px;
        border-radius: 50%;
        background-color: #c0c4cc;
      }
    }
    
  }
  .filter_Criteria{
    width: 100%;
    
    .screen-item{
      display: flex;
      align-items: center;
      margin: 15px 0;
        .left{
          width: 110px;
          color: #c9c9c9;
          font-size: 14px;
        }
        .left2{
          width: 200px;
          color: #c9c9c9;
          font-size: 14px;
        }
        .right{
          display: flex;
          align-items: center;
          color: #101010;
          font-size: 14px;
          span{
            display: inline-block;
            width: 70px;
            padding: 2px 0;
            margin-right: 20px;
            text-align: center;
            border-radius: 10px;
            cursor:pointer
          }
        }
    }
    .nocheck{
      border: 1px solid #bbbbbb;
    }
    .check{
      color: #fea429;
      border: 1px solid #fea429;
    }
    .prohibit{
      border: 1px solid #C0C4CC;
      color: #C0C4CC;
      // cursor:pointer
      pointer-events: none;
      cursor: not-allowed
    }
  }
  .inverterList{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    .inverterList_item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 140px;
      margin: 10px 2px;
      font-size: 14px;
      color: #101010;
      position: relative;
      .PopularHot{
        position: absolute;
        top: 0px;
        left:10px;
        color: #fff;
        display: flex;
        // height: 20px;
        padding: 0 8px;
        border-radius: 10px;
        background-color: tomato;
      }
      .item {
  margin-top: 10px;
  margin-right: 40px;
}
      span{
        text-align: center;
      }
    }
  }
}
::v-deep .el-input__inner{
    border-radius: 10px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"]{
  -moz-appearance: textfield;
}
::v-deep .el-checkbox{
  color: #101010;
}
</style>