<!--
 * @Author: your name
 * @Date: 2020-11-26 09:52:24
 * @LastEditTime: 2020-12-21 16:58:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterData.vue
-->
<template>
    <div class="container-module">
        <div class="left-sub-box">
            <el-card class="box-card">
                <div slot="header" class="clearfix" @click="addModuleData">
                    <i class="el-icon-plus"></i><span>{{$t('project.data.add-PV-array')}}</span>
                </div>
                <div>
                    <el-form ref="forms" :model="data" label-width="190px" label-position='left' class="inline">
                        <el-row :gutter="24" style="width:1050px;margin:0 auto 40px" v-for="(form, index) in data.forms" :key='index'>
                            <el-button  icon="el-icon-delete" type="primary" @click="deleteMoudle(form,index)"
                                        class="pull-right" circle></el-button>
                            <el-col :span="12" style="width:390px;margin-right:50px">
                                <el-form-item :label="$t('new.PV.management.name')" :prop="'forms.' + index + '.name'"  :rules="[{ required: true, message: $t('remind.not-null')}]" label-width="160px">
                                    <el-input v-model="form.name" @blur="cache(index)" :validate-event="false"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('new.PV.management.manufacturer')" :prop="'forms.' + index + '.manufacturerId'"  :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]" label-width="160px">
                                    <el-select ref="pvmodule" v-model="form.manufacturerId" @change="manufacturerChange(form,index)" filterable>
                                        <el-option
                                                v-for="item in manufacturerList"
                                                :key="item.manufacturerID"
                                                :label="item.name"
                                                :value="item.manufacturerID">
                                        </el-option>    
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.module.select-name')" :prop="'forms.' + index + '.moduleId'"  :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]" label-width="160px">
                                    <el-select ref="oldpvmodule" v-model="form.moduleId" :placeholder="$t('common.msg.select.please')" @change="changeModule(form,index)" filterable>
                                        <el-option
                                            v-for="item in tMoudleListsNew[form.manufacturerId]"
                                            :key="item.moduleID"
                                            :label="item.name"
                                            :value="item.moduleID">
                                        </el-option>
                                    </el-select>
                                    <i class="el-icon-tickets"
                                        v-show="form.moduleId!=''||form.moduleId!=null"
                                       style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);"
                                       @click="showDetailModal(form)">
                                    </i>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" style="width:550px;">
                                <el-row :gutter="24">
                                    <el-col :span="4" class="title">
                                        {{$t('new.PV.management.setpoint')}}
                                    </el-col>
                                    <el-col :span="20">
                                        <el-form-item >
                                            <span slot="label">
                                                <el-radio v-model="form.selectLable" :label="1" :disabled="form.moduleId==null">{{$t('PV.number.of.pv.module')}}:</el-radio>
                                            </span>
                                            <el-input 
                                                class="small" 
                                                v-model="form.moduleCount" 
                                                @input="value => form.moduleCount=value.replace(/[^(0-9)]/g,'')"
                                                :disabled="form.selectLable !=1||form.moduleId==null"
                                                @blur="calc(form , index)">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item >
                                            <span slot="label">
                                                <el-radio v-model="form.selectLable" :label="2" :disabled="form.moduleId==null">{{$t('project.data.PV-peak-power')}}</el-radio>
                                            </span>
                                            <el-input-number 
                                                class="small inputNew" 
                                                type="number" 
                                                v-model="form.peakPower" 
                                                :disabled="form.selectLable!=2||form.moduleId==null"
                                                @blur="peakPowerChange(form , index)"
                                                :precision="2"
                                                controls-position="right"
                                                :controls='false'
                                                style="width:80px;"
                                                size='small'
                                                 >
                                            </el-input-number>
                                            <el-select v-model="form.select" class="small" style="width:75px" :disabled="form.selectLable!=2||form.moduleId==null" @change="selectChange(index , form)">
                                                <el-option :value="1" label="Wp"></el-option>
                                                <el-option :value="2" label="kWp"></el-option>
                                                <el-option :value="3" label="MWp"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4" class="title">
                                       {{$t('new.PV.management.orientation')}}
                                    </el-col>
                                    <el-col :span="20">
                                        <el-form-item :label="$t('new.PV.management.inclination')" style="margin-top:0px">
                                            <el-select 
                                                v-model="form.inclination" 
                                                class="small" 
                                                :disabled ="form.proposalIncli||form.moduleId==null"
                                                @change="cache(index)">
                                                <el-option v-for="n in 90" :key="n" :value="n" :label="n"></el-option>
                                            </el-select>
                                            <el-checkbox v-model="form.proposalIncli" :disabled="form.moduleId==null" @change="changeAngle(form,index)">{{$t('new.PV.management.recommended-angle')}}</el-checkbox>
                                            <angleAdjustment :num="-form.inclination" ref="angle" @angle="angle(form,index,1)"  @click="()=>{}"></angleAdjustment>
                                        </el-form-item>
                                        <el-form-item :label="$t('new.PV.management.azimuth-angle')+':'" style="margin-top:30px">
                                            <el-select 
                                                v-model="form.azimuth" 
                                                class="small" 
                                                :disabled="form.proposalAzimuth||form.moduleId==null"
                                                @change="cache(index)">
                                                <el-option v-for="n in 361" :key="n" :value="n-1" :label="n-1"></el-option>
                                            </el-select>
                                            <el-checkbox v-model="form.proposalAzimuth" :disabled="form.moduleId==null" @change="changeAngle2(form,index)">{{$t('new.PV.management.recommended-angle')}}</el-checkbox>
                                            <!-- <angleAdjustment :num="-form.azimuth" ref="angle" @angle="angle(form,index,2)" @click.native="()=>{}"></angleAdjustment> -->
                                            <azimuth :num="form.azimuth" @angle="angle(form,index,2)" @click.native="()=>{}"></azimuth>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                    </el-form>
                </div>
            </el-card>
        </div>
        <div class="right-sub-box">
            <rightReport :poFormData="poFormData" :dataVisible="dataVisible" @clickNext="clickNextM"></rightReport>
        </div>
        <moduleDetail :detailData="detailData"></moduleDetail>
    </div>
</template>
<script>
import rightReport from './rightReport'
import moduleDetail from './moduleDetail'
import angleAdjustment from '@/views/projectList/angleAdjustment'
import azimuth from '@/views/projectList/azimuth'
export default {
    components: {
        rightReport,
        moduleDetail,
        angleAdjustment,
        azimuth
    },
    props: {
        azimuth:{
            type: Object,
            default () {
                return {}
            }
        },

        data:{
            type: Object,
            default () {
                return {}
            }
        },
        active:{
            type:Number,
            default:1,
        },
        poFormData:{
            type:Object,
            default:()=>{return {}}
        },
        cityId:'',
        isEdit:{
            type:Boolean,
            default:()=>{return true}
        },
        next:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {
            proId:this.$route.query.id,
            detailData:{},
            dataVisible:{
                total: false,
                performance: false,
                overview: true
            },
            manufacturerList:[],
            tMoudleListsNew:{},
            isEditData:this.isEdit,
            firstFlag:false
        };
    },
    computed: {
    },
    watch: {
        data:{
            handler(nv,ov){
                if(nv){
                    if(nv.forms.length>0){
                        nv.forms.forEach((item,i) =>{
                            if(item.manufacturerId&&this.isEditData){
                                this.systemTMoudleGetList(item.manufacturerId,i)
                                this.isEditData=false
                            }
                        })
                    }
                }
            },
            deep:true
        },
        active:{
            handler(nv,ov){
                if(nv != 2 &&ov == 2){
                    // this.cache(0)
                    this.tprojectSave()
                }
                if(nv == 2 &&ov != 2){
                    // this.cache(0)
                    this.$emit('changeNext',true)
                    // let pvFlag = 0
                    // this.data.forms.forEach((item)=>{
                    //     if (!item.manufacturerId&&!item.moduleId) {
                    //         pvFlag = pvFlag + 1
                    //     }
                    // })
                    // console.log(pvFlag,'pvFlag');
                    // if (pvFlag == this.data.forms.length) {
                        this.$nextTick(() => {
                            console.log(1111);
                            this.$refs["forms"].clearValidate()
                        })
                    // }
                }
            },
            immediate:true,
        }
    },
    created() {
        
    },
    mounted() {
        this.systemTManufacturerGetList()
        this.addModuleData(true)//获取光伏模块初始值
        // this.$nextTick(() => {
        //     this.$refs["forms"].clearValidate()
        // })
    },
    methods: {
        // 每个步骤自动保存
        async tprojectSave(){
            let status = parseInt(localStorage.getItem('statusF'))
            if (status < 2) {
                status = 2
                localStorage.setItem("statusF",2)
            }
            let res = await this.API.tprojectSave({
                proId:this.$route.query.id,
                status
            })
        },
        // 调整角度
        angle(form,index,flag){
            let data = localStorage.getItem("angle")
            // 调整倾角
            if (flag==1) {
                this.$set(form,'inclination', Math.abs(parseInt(data)))
                this.$set(form,'proposalIncli',false)
                this.cache(index)
            // 调整方位
            }else if(flag==2){
                this.$set(form,'azimuth',parseInt(data))
                this.$set(form,'proposalAzimuth',false)
                this.cache(index)
            }
            
        },


        //添加光伏阵列
        
        async addModuleData(isNew){
            // let data = Object.assign({} , this.moudleData)
            // this.manufacturerLists.push(Object.assign({} , this.manufacturerList))
            // this.data.forms.push(data)
            
            let index=isNew==true?0:this.data.forms.length
            let res = await this.API.selectModule({
                pvArrayId:index,
                projectId:this.proId,
                // name:this.$t('project.module.select-name')+(index+1)
            })
            if(isNew==true){
                (res.pvArrays||[]).forEach((item,index)=>{
                    this.data.forms=[{
                        name: item.name||this.$t('project.module.select-name')+(index+1),
                        manufacturerId: item.manufacturerId||'',
                        moduleId: item.moduleId||'',
                        moduleCount: item.moduleCount,
                        inclination: item.inclination,
                        proposalIncli: item.proposalIncli,
                        azimuth: item.azimuth,
                        proposalAzimuth: item.proposalAzimuth,
                        usedModuleCount: item.usedModuleCount,
                        peakPower: item.peakPower/1000,//默认单位kWp,需要除1000
                        originPearPower:item.peakPower,
                        selectLable:1,
                        select:2,//默认单位kWp
                    }]
                })
            }else{
                this.$emit('noticeUpload',res)
            }
        },
        //删除光伏阵列
        async deleteMoudle(form,index){
            if(this.data.forms.length >1){
                let res = await this.API.deletePvArray({
                    projectId: this.proId,
                    pvArrayId:index
                })
                // this.data.forms.splice(index,1);
                this.$emit('noticeUpload',res)
            }else{
                this.$message.warning(this.$t('retain.project.pv'));
                return
            }
        },
        //修改光伏阵列
        async changeModuleFun(pvArrayId){
            let params=this.data.forms[pvArrayId]
            params.pvArrayName=this.data.forms[pvArrayId].name
            params.pvArrayId=pvArrayId
            params.projectId=this.proId
            let res = await this.API.selectModule(
                params
            )
            this.$emit('PoFormUpload',res)
        },
        //切换供应商
        manufacturerChange(form,i){
            let manufacturerId = form.manufacturerId
            form.moduleId=null
            this.$set(form,'moduleId',null)
            this.systemTMoudleGetList(manufacturerId,i)
            this.$refs['forms'].validateField('forms.' + i + '.manufacturerId')
        },


        //切换模块
        changeModule(form,index){
            // 判断是否点击的more
            if (form.moduleId == 'more') {
                let manufacturerId = form.manufacturerId
                form.moduleId = ''
                this.$nextTick(()=>{
                    this.$refs.oldpvmodule[index].visible = true
                })
                this.systemTMoudleGetList(manufacturerId,index,1)
                return
            }
            let moduleId=form.moduleId
            this.tMoudleListsNew[form.manufacturerId].find(item=>{
                if(item.moduleID==moduleId&&item.pmodMPP!=null){
                    form.pModMPP=item.pModMPP
                    let peakPower = form.pModMPP * form.moduleCount
                    this.changeMoudleSelect(form , peakPower)
                    form.total=item.pmodMPP*form.moduleCount
                }
            })
             this.$refs['forms'].validateField('forms.' + index + '.moduleId')
            this.cache(index)
        },


        changeAngle(form,index){
            if(form.proposalIncli){
                // 设置建议角度
                this.$set(form,'inclination', this.azimuth.inclinationProposal || 30 )
                this.cache(index)
            }
        },
        changeAngle2(form,index){
            if(form.proposalAzimuth){
                this.$set(form,'azimuth',this.azimuth.azimuthProposal || 0)
                this.cache(index)
            }
        },

        //额定功率计算
        calc(form , index){
            // 峰值功率 = 光伏模块数 * 额定功率
            let peakPower = form.pModMPP * form.moduleCount
            this.changeMoudleSelect(form , peakPower)
            // 缓存光伏模块数据
            this.cache(index)
        },


        /*峰值功率选择改变*/
        selectChange(index , form){
            let del = 1;
            if(form.select == 2){
                del = 1000
            }else if(form.select == 3){
                del = 1000000
            }
            /*当峰值功率小于额定功率时*/
            if(form.pModMPP > del*form.peakPower){
                let peakPower = form.pModMPP
                this.$set(form,'peakPower',peakPower)
                this.$set(form,'moduleCount',1)
            }else {
                /*峰值功率单位变大*/
                let moduleCount = Math.ceil(form.peakPower*del/form.pModMPP);
                this.$set(form,'moduleCount',moduleCount)
                this.$set(form,'peakPower', (moduleCount*form.pModMPP)/del)
            }
            this.cache(index)
        },


        /*峰值功率变化*/
        peakPowerChange(form , index){
            // 根据峰值功率计算光伏模块数
            let del = 1;
            if(form.select == 2){
                del = 1000
            }else if(form.select == 3){
                del = 1000000
            }
            if(form.pModMPP > del*form.peakPower){
                let peakPower = form.pModMPP
                this.$set(form,'peakPower',peakPower)
                this.$set(form,'moduleCount',1)
            }else {
                /*峰值功率单位变大*/
                let moduleCount = Math.ceil(form.peakPower*del/form.pModMPP);
                this.$set(form,'moduleCount',moduleCount)
                // 设置峰值功率单位
                this.changeMoudleSelect(form , form.peakPower*del)
            }
            this.cache(index)
        },

        /*光伏模块数改变时修改峰值功率以及单位*/
        changeMoudleSelect(form , peakPower){
            if(peakPower > 1000*1000){
                this.$set(form,'select',3)
                this.$set(form,'peakPower',peakPower/1000000)
            }else if(peakPower > 1000 ){
                this.$set(form,'select',2)
                this.$set(form,'peakPower',peakPower/1000)
            }else {
                this.$set(form,'select',1)
                this.$set(form,'peakPower',peakPower)
            }
        },




        /*获取供应商下拉框*/
        // mainManufacturer  0:非主要厂商 1:主要厂商
        async systemTManufacturerGetList(){
            let res = await this.API.systemTManufacturerGetList({})
            // if (mainManufacturer==1) {
            //     let more = {
            //         manufacturerID: 'more',
            //         name: "More"
            //     }
            //     res.push(more)
            // }
            this.manufacturerList = res
        },
        /*获取模块下拉框*/
        async systemTMoudleGetList(id,i,queryType=0){
            let arr = []
            let res = await this.API.systemTMoudleGetList({
                manufacturerID: id,
                queryType
            })
            // 添加more
            if (queryType==0) {
                let more = {
                    moduleID: 'more',
                    name: "More"
                }
                res.push(more)
                this.MoudleGetListAll(id)
            }
            if(this.data.forms[i].moduleId){
                res.forEach(item=>{
                    if(item.moduleId==this.data.forms[i].moduleId){
                        this.$set(this.data.forms[i],'pmodMPP',item.pmodMPP)
                    }
                })
            }
            this.$set(this.tMoudleListsNew,id,res)
        },
        async MoudleGetListAll(id,i,queryType=1){
            let arr = []
            let res = await this.API.systemTMoudleGetList({
                manufacturerID: id,
                queryType
            })
            if (!res.length) {
                this.$set(this.tMoudleListsNew,id,[])
            }
        },
        /*根据光伏模块获取光伏阵列默认值*/
        async getPvDefault(form,index){
            let res = await this.API.getPvArrayDefaultValue({
                cityId: this.cityId,
                moduleId:form.moduleId
            })
            // console.log(res)
            if(res){
                if(form.proposalAzimuth) form.azimuth=res.azimuth
                if(form.proposalIncli) form.inclination=res.inclination
                this.$set(form,'d_azimuth',res.azimuth)
                this.$set(form,'d_inclination',res.inclination)
            }
        },
        //校验并缓存
        cache(index){
            //  console.log(index,"index");
            // this.$refs['forms'].validate((valid) => {
            //     console.log('up');
            // if (valid) {
            //     console.log('offM');
                this.changeModuleFun(index)
            //     this.$emit('changeNext',true)
            // } else {
            //     this.$emit('closeAll',false)
            //     return false;
            // }
            // });
           
        },
        //查看光伏模块详细信息
        async showDetailModal(form){
            let moduleId=form.moduleId
            if(moduleId){
                this.$store.commit('setVisibleShow',true)
                let res = await this.API.systemModuleGetById({
                    moduleID: moduleId
                })
                this.detailData = res
            }
        },
        // 点击next
        clickNextM(){
            this.$refs['forms'].validate((valid) => {
                if (valid) {
                    this.$emit('changeNext',true)
                }
            if (valid&&this.next) {
                this.$emit('nextMenus',this.active+1)
                this.$emit('changeNext',false)
            } else {
                this.$emit('closeAll',false)
                return false;
            }
            });
        }
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio,/deep/ .el-radio__input{
    white-space: wrap;
}
</style>
<style  lang="less" >
.container-module{
    display: flex;
    .el-card__header{
        background-color: #ddd;
        cursor:pointer;
    }
    
    .left-sub-box{
        flex: 9;
        padding: 10px;
        .box-card{
            .el-card__header:hover{
                color: rgb(44, 154, 197);
            }
        }
    }
    .right-sub-box{
        flex: 3;
        padding: 10px;
    }
    .img-and-table-box{
        display: flex;
        .img-box{
            flex: 1;
        }
        .table-box{
            flex: 7;
        }
    }
    .el-table::before{
        background-color: #fff;
    }
    .el-table td{
        border-bottom: none;
        text-align: center;
    }
    .el-table th.is-leaf{
        border-bottom: none;
        text-align: center;
    }
    .margin-top20{
        margin-top:20px;
    }
    .green-color{
        color: green;
    }
    .red-color{
        color: red;
    }
    

    .inline{
        .el-input,
        .el-select{
            display: inline-block;
            width: 180px;
            margin-right: 10px;
            &.small{
                width: 100px;
                &>.el-input{
                    width: 80px;
                }

            }
            .el-form-item--small .el-form-item__error {
                left: 208px;
            }
        }
        .input-title{
            height: 80px;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .title{
        line-height: 70px;
        font-size: 14px;
        font-weight: bold;
    }

    .el-icon-tickets{
        position: absolute;
        right: 0px;
        top: 10px;
    }
    .inputNew{
        .el-input__inner{
            width: 80px !important;
            text-align: left;
            padding-left: 10px;
        }
    }
    
}
</style>
