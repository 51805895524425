<template>
  <div class="box">
    <div class="angleBox preserve-3d">
      <div class="angleBox-page-box angleBox-page-4 preserve-3d">
        <div class="angleBox-page page-front"></div>
      </div>

      <div
        class="angleBox-page-box angleBox-page-1 preserve-3d"
        ref="angle"
        @mousedown="mousedown"
        :style = "{transform:'rotateY('+num+'deg)'}"
      >
        <div class="angleBox-page page-front"></div>
      </div>
      <!-- <div style="width:100px;height:100px;background:tomato"></div> -->
    </div>
  </div>
</template>

<script>
export default {
   props: {
    num: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      ax: "",
      ay: "",
      bx: "",
      by: "",
      angleChange:''
    };
  },
  mounted() {
    console.log('我生效了');
  },
  watch:{
     num:{
            handler(nv,ov){
              localStorage.setItem("angle",nv)
            },
            deep:true,
            immediate:true
        }
  },
  methods: {
    mousedown(e) {
      console.log("我被调用了",e);
      let ax = e.clientX;
      let ay = e.clientY;
      let angle = this.$refs.angle;
       var than = this
      document.onmousemove = function (e) {
        // console.log("我也被调用了",e);
        if (ax) {
          let bx = e.clientX;
          let by = e.clientY;
          var xx = bx - ax;
          var yy = by - ay;
          var xrad = Math.atan2(yy, xx);
          var rotation = (xrad / Math.PI) * 180;
          if (rotation>0) {
            rotation = 0
          }else if (rotation<-90) {
            rotation = -90
          }
          // console.log(this.fun);
          
          localStorage.setItem("angle",rotation)
          
          angle.style.transform = `rotateY(${rotation}deg)`;
          
        }
      };
      document.onmouseup = function (e) {
        than.$emit('angle')
        document.onmousemove = null;
        document.onmouseup = null;
      };
      // this.$emit('angle',rotation)
    },

   
  },
};
</script>

 <style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}

body {
  perspective: 1000px;
  background-color: #606266;
}
.box{
  // float: left;
  // height: 40px;
}
.angleBox {
  position: absolute;
  top: -8px;
  left: 105%;
  //   margin-top: -150px;
  width: 50px;
  height: 50px;
  background-color: #ffdba8;
  transform: rotateX(78deg) rotateY(0deg) rotateZ(-5deg);
}
.preserve-3d {
  transform-style: preserve-3d;
}
.angleBox-page {
  position: absolute;
  top: 0px;
  left: 0;
  width: 50px;
  height: 50px;
  // border: 1px solid skyblue;
  // box-shadow:0 15px 35px rgba(71, 54, 54, 0.5);
  text-align: center;
}
.angleBox-page-box {
  transform-origin: 0 50%;
  transform: rotateY(0deg);
}
.angleBox-page-1 .page-front {
  background-color: #fea429;
}
</style>