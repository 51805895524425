<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 14:51:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div>
        <el-dialog
        :title="$t('project.battery-info')"
        :visible="visibleInfo"
        width="40%"
        :before-close="handleClose">
        
        <div class="inverterDetail-main-box">
            <el-row :gutter="20">
                <el-col :span="20" :offset="1">
                    <!-- <el-divider content-position="left">{{$t('project.regular-data')}}</el-divider> -->
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Nominal-voltage')}}</div>
                        <div>{{detailData.nominalVolatage}} (V)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Total-energy')}}</div>
                        <div>{{detailData.totalEnergy}} (kWh)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Usable-energy')}}</div>
                        <div>{{detailData.height}} (kWh)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Rated-capacity')}}</div>
                        <div>{{detailData.ratedCapacity}} (Ah)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Nominal-power')}}</div>
                        <div>{{detailData.nominalPower}} (kW)</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <!-- <el-divider content-position="left">{{$t('project.high-switching')}}</el-divider> -->
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Max.-power')}}</div>
                        <div>{{detailData.maxPower}} (kW)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Battery-to-Inverter')}}</div>
                        <div>{{detailData.batteryToInverter}} </div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <!-- <el-divider content-position="left">{{$t('project.input-data')}}</el-divider> -->
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Battery-to-battery/BMS')}}:</div>
                        <div>{{detailData.batteryTobattery}} </div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.System-switch')}}:</div>
                        <el-checkbox v-model="detailData.systemSwitch" ></el-checkbox>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Width')}}</div>
                        <div>{{detailData.width}} (mm)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Height')}}</div>
                        <div>{{detailData.height}} (mm)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Depth')}}</div>
                        <div>{{detailData.depth}} (mm)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Net-weight')}}</div>
                        <div>{{detailData.netWeight}} (kg)</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <div class="modal-box-flex">
                        <div style="flex: inherit;">{{$t('project.input-current')}}</div>
                        <div>{{returnA()}} A</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <!-- <el-divider content-position="left">{{$t('project.output-data')}}</el-divider> -->
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Recommend-charge/discharge-current')}}</div>
                        <div>{{detailData.recommendCurrent}} (A)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Max.-charge/discharge-current')}}</div>
                        <div>{{detailData.maxCurrent}} (A)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Max.-charge/discharge-current')}}</div>
                        <div>{{detailData.maxCurrent}} (A)</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Battery-roundtrip-efficiency')}}</div>
                        <div>{{detailData.roundtripEfficiency}}</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Cycle-life')}}</div>
                        <div>{{detailData.cycleLife}}</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <!-- <el-divider content-position="left">{{$t('import.time')}}</el-divider> -->
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('battery.data.Expected-life-time/Warranty')}}</div>
                        <div>{{detailData.expectedLife}}</div>
                    </div>
                </el-col>
                
            </el-row>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        detailData:{
            type:Object,
            default:()=>{return {}}
        }
    },
    data() {
        return {

        };
    },
    computed: {
        visibleInfo(){
            return this.$store.state.sysStore.visibleBatteryInfo
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleBatteryInfo',false)
        },
        returnA(){
            let data = this.detailData.tpvParamList || []
            let arr = data.map(item => item.iInvDCMax)
            return arr.join('/')
        }
    },
};
</script>

<style  lang="less" scoped>
.inverterDetail-main-box{
    .modal-box-flex{
    display: flex;
    justify-content: space-between;
    padding: 5px;
        div:nth-child(1){
        flex: 2
        }
        div:nth-child(2){
        flex: 1
        }
    }
}
</style>
