<!--
 * @Author: your name
 * @Date: 2020-11-26 09:52:24
 * @LastEditTime: 2021-04-07 16:32:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterData.vue
-->
<template>
    <div class="inverterData-main-box">
        <div class="left-sub-box" v-if="screenflag">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span @click="addInverterData">{{ $t('inverter.info.add') }} <i class="el-icon-plus"></i></span>
                </div>
                <div>
                    <el-form ref="form" v-if='form.forms.length > 0' :model="nowTableData" label-width="200px"
                        label-position='left'>
                        <el-row :gutter="20">
                            <!-- 逆变器 -->
                            <el-col :span="9" :offset="1">
                                <el-form-item :label="$t('project.data.inverter')" prop="inverterId"
                                    :rules="[{ required: true, message: $t('remind.not-null') }]" label-width="80px">
                                    <!-- <el-select ref="oldinverter" style="width:95%;"  v-model="nowTableData.inverterId" :placeholder="$t('common.msg.select.please')" filterable @change="inverterChange(nowTableData.inverterId)" @click="screenflag = false"> -->
                                    <!-- <el-select ref="oldinverter" style="width:95%;"  v-model="nowTableData.inverterId" :placeholder="$t('common.msg.select.please')" filterable  @focus="changeInv(nowTableData)"> -->
                                    <!-- <el-option
                                                v-for="item in inverterList"
                                                :key="item.invID"
                                                :label="item.name"
                                                :value="item.invID + ''">
                                        </el-option>
                                    </el-select> -->
                                    <el-input ref="oldinverter" style="width:95%;" v-model="nowTableData.invName"
                                        :placeholder="$t('common.msg.select.please')"
                                        @focus="changeInv(nowTableData)"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- 逆变器数量 -->
                            <el-col :span="8" :offset="1">
                                <el-form-item :label="$t('project.data.number-of-inverters')" prop="inverterNum"
                                    :rules="[{ required: true, message: $t('remind.not-null') }]" label-width="180px">
                                    <el-input style="width:100%;" v-model="nowTableData.inverterNum" @input="updateView"
                                        :placeholder="$t('common.info.pl-insert-content')"
                                        @blur='inverterNumChange'></el-input>

                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="4" ><span v-show="nowTableData.inputCount < 5" @click="computeBtn" class="computeBtn" >推荐方案</span></el-col> -->
                            <!-- <el-col :span="7" :offset="1">
                                <el-form-item :label="$t('project.data.rated-power-ratio')" prop="nomPowerFrom" :rules="[{ required: true, message: $t('remind.not-null')}]"
                                              label-width="165px">
                                    <el-input style="width:100%;" v-model="nowTableData.nomPowerFrom" :placeholder="$t('common.info.pl-insert-content')" ></el-input>
                                    
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                    </el-form>
                    <div class="img-and-table-box" v-if='nowTableData.inverterId'>
                        <div class="img-box">
                            <img :src="imgUrlBase" alt="" width="100%" style="max-width:150px;" v-if='imgFlag'>
                            <div @click="showDetailModal(true)" style="font-size: 14px;color:#fea429">More info</div>
                        </div>
                        <div class="table-box">
                            <div class="selectPvModule">
                                <!-- <p class="selectPvTip">{{$t('new.PV.management.PV-array')}}</p>
                                    <el-checkbox-group  v-model="checkedPv" :min="0" :max="nowTableData.inputCount" style="margin:10px 0">
                                        <el-checkbox @change="changePvModule($event,index)" v-for="(item,index) in form.pvArrays" :label="item.id" border :key="item.id">{{item.name}}</el-checkbox>
                                    </el-checkbox-group> -->
                                <!-- <el-tooltip class="item" effect="dark" :content="$t('pv.data.recomment')" placement="top">
                                        <div class="computeBtn"><el-button style="backgroundColor: #fea429;color:white"  @click="computeBtn">{{$t('pv.data.compute')}}</el-button></div>
                                    </el-tooltip> -->
                                <el-dialog :title="$t('pv.data.Recommended-scheme')" :visible.sync="dialogVisible"
                                    width="80%" modal>
                                    <span>
                                        <div v-loading="loading" style="min-height:200px">

                                            <div class="selectPvModule">
                                                <p class="selectPvTip">{{ $t('new.PV.management.PV-array') }}</p>
                                                <el-checkbox-group v-model="checkedPv" :min="0"
                                                    :max="nowTableData.inputCount" style="margin:10px 0">
                                                    <el-checkbox @change="changePvModule($event, index)"
                                                        v-for="(item, index) in form.pvArrays" :label="item.id" border
                                                        :key="item.id">{{ item.name }}</el-checkbox>
                                                </el-checkbox-group>
                                            </div>
                                            <div v-if="noRecommendFlag && checkedPv.length > 0"
                                                style="margin:0 auto;text-align:center">{{ $t('No-recommended') }}</div>
                                            <div class="recommend">
                                                <el-card shadow="hover" class="recommendCard"
                                                    @click.native="selectCard(Recommendsitem)"
                                                    v-for="Recommendsitem in PvRecommendList"
                                                    :key="Recommendsitem.pvList.pvRecommends[0].id">
                                                    <div class="tip">
                                                        {{ $t('project.nominal-power-ratio') }}:{{ Recommendsitem.pvList.nomRatio }}
                                                    </div>
                                                    <el-table :data="Recommendsitem.pvList.pvRecommends"
                                                        style="width: 100%">
                                                        <el-table-column type="index" label="" :index="indexMethod"
                                                            width="70px">

                                                        </el-table-column>
                                                        <el-table-column prop="pvArray.name" :label="$t('module.name')"
                                                            width="110px">
                                                        </el-table-column>
                                                        <el-table-column prop="moduleNum" :label="$t('project.data.number')"
                                                            width="80px">
                                                        </el-table-column>
                                                        <el-table-column prop="stringcount"
                                                            :label="$t('new.PV.management.strings')" width="80px">
                                                        </el-table-column>
                                                    </el-table>
                                                </el-card>
                                            </div>


                                        </div>

                                    </span>
                                    <!-- <span slot="footer" class="dialog-footer">
                                            <el-button @click="dialogVisible = false">取 消</el-button>
                                            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                                        </span> -->
                                </el-dialog>
                            </div>

                            <el-table :data="tableData" max-height="250" :border='false' style="width: 100%" align='center'
                                v-if='form.forms'>
                                <el-table-column type="index" style="50px" :label="$t('new.PV.management.input')"
                                    align='center' :index='(index) => { return "MPPT " + (index + 1) }' width="120">
                                    <!-- template注释了 -->
                                    <!-- <template slot-scope="scope">
                                        {{scope.row}}
                                    </template> -->
                                </el-table-column>
                                <el-table-column prop="id" :label="$t('new.PV.management.PV-array')">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.pvArrayId"
                                            :placeholder="$t('common.msg.select.please')"
                                            @change='pvArrayChange(scope.row)'>
                                            <el-option v-for="item in form.pvArrays" :key="item.name" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="stringCount" :label="$t('new.PV.management.strings')">
                                    <template slot-scope="scope">
                                        <div style="padding:20px;">
                                            <el-input-number v-model="scope.row.stringCount" controls-position="right"
                                                :min="1" @blur="countBlur(scope.row)"
                                                @change='tprojectSelectInverter({ stringCount: scope.row.stringCount, invInputId: scope.row.index })'
                                                :disabled="scope.row.pvArrayId == 0 ? false : scope.row.pvArrayId ? false : true"></el-input-number>
                                            <br>
                                            <span
                                                style='position:absolute;left:50%;transform: translateX(-50%);'>1...{{ scope.row.stringCountMax }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="c" label="">
                                    <template slot-scope="scope">
                                        X
                                    </template>
                                </el-table-column>
                                <el-table-column prop="moduleCount" :label="$t('project.PV')">
                                    <template slot-scope="scope">
                                        <el-input-number v-model="scope.row.moduleCount" controls-position="right" :min="1"
                                            @blur="modulCountBlur(scope.row)"
                                            @change='tprojectSelectInverter({ moduleCount: scope.row.moduleCount, invInputId: scope.row.index })'
                                            :disabled="scope.row.pvArrayId == 0 ? false : scope.row.pvArrayId ? false : true"></el-input-number>
                                        <br>
                                        <span
                                            style='position:absolute;left:50%;transform: translateX(-50%);'>{{ scope.row.moduleCountMin }}...{{ scope.row.moduleCountMax }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <p style="text-align:center;color:#999;">{{$t('nominal.Power.Ratio.range')}}{{detailData.nomPowerFrom}}% ~ {{detailData.nomPowerTo}}%</p> -->
                        </div>
                    </div>
                    <p v-if='nowTableData.inverterId' class="Overbalance">
                        <span></span>
                        <span @click="changeNomPowerFrom(1)">
                            {{ $t('nominal.Power.Ratio.range') }}：
                            <!-- <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('common.msg.changeNom-PowerFrom')"
                                placement="top"
                                >
                                <i class="el-icon-question" style="color: orange; font-size: 16px" ></i> 
                            </el-tooltip>： -->
                            <span v-if="nomPowerFromVisi">{{ this.nowTableData.nomPowerFrom || 100 }}% </span>
                            <el-input ref="nomPowerFrom1" v-else v-model="nomPowerFrom" @blur="changeNomPowerFrom(2)"
                                style="width:100px;height:21px"
                                :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            ~
                            <span>{{ this.nowTableData.nomPowerTo || 130 }}% </span>
                            <i class="el-icon-edit" v-if="nomPowerFromVisi"></i>
                        </span>
                        <span v-if="nowTableData.inputCount < 5" @click="computeBtn" class="computeBtn"><el-button
                                type="primary">{{ $t('pv.data.Recommended-scheme') }}</el-button></span>
                        <span v-else></span>
                    </p>
                </div>
            </el-card>
            <el-card class="margin-top20" v-if='form.forms.length > 0'>
                <div slot="header" class="clearfix">
                    <span>{{ $t('new.PV.management.overview-of-inverters') }}</span>
                </div>
                <div>
                    <el-table :data="form.forms" :key="inverterDetailVisi" :border='false' align='center'
                        @row-click='tableClick' :row-class-name="tableRowClassName">
                        <el-table-column fixed prop="d" label="" width="160">
                            <template slot-scope="scope">
                                <i v-if='scope.row.statusImg == 0' class="el-icon-success"
                                    style="color:green;font-size:16px;"></i>
                                <i v-if='scope.row.statusImg == 1' class="el-icon-warning"
                                    style="color:orange;font-size:16px;"></i>
                                <i v-if='scope.row.statusImg == 2' class="el-icon-warning"
                                    style="color:red;font-size:16px;"></i>
                                {{ scope.row.invName }}
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(pvArray, index) in form.pvArrays" :key='pvArray.id' width="150"
                            v-if='pvArraysInvFlag'>
                            <template slot="header" slot-scope="scope">
                                {{ pvArray.name }}
                                <br>
                                <span class="green-color"
                                    v-if='parseInt(pvArray.usedModuleCount) <= parseInt(pvArray.moduleCount)'>{{ pvArray.usedModuleCount
                                        + '/' + pvArray.moduleCount }}</span>
                                <span class="green-color" style="color:red;" v-else>{{ pvArray.usedModuleCount + '/' +
                                    pvArray.moduleCount }}</span>
                            </template>
                            <template slot-scope="scope">
                                <span v-if='item.pvArrayId == pvArray.id' v-for="(item, index1) in scope.row.inverterInputs"
                                    :key='index1'>
                                    {{ item.stringCount + '/' + item.moduleCount + '(' + parseInt(index1 + 1) +
                                        ')' }}<br></span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" :label="$t('project.nominal-power-ratio')" min-width="150">
                            <template slot-scope="scope">
                                {{ scope.row.nominalPowerRatio }}
                                <i v-if='parseInt(scope.row.nominalPowerRatio) >= scope.row.nomPowerFrom && parseInt(scope.row.nominalPowerRatio) <= scope.row.nomPowerTo'
                                    class="el-icon-success" style="color:green;font-size:16px;"></i>
                                <el-tooltip v-else-if='parseInt(scope.row.nominalPowerRatio) < scope.row.nomPowerFrom'
                                    placement="top">
                                    <div slot="content">{{ $t('nominal.Power.Ratio.smaller0') }}（
                                        < {{ scope.row.nomPowerFrom }}%）。<br />
                                        {{ $t('nominal.Power.Ratio.smaller1') }}<br />{{ $t('nominal.Power.Ratio.smaller2') }}<br />
                                        {{ $t('nominal.Power.Ratio.smaller3') }}
                                    </div>
                                    <i class="el-icon-warning" style="color:orange;font-size:16px;"></i>
                                </el-tooltip>
                                <el-tooltip
                                    v-else-if='parseInt(scope.row.nominalPowerRatio) > scope.row.nomPowerTo && parseInt(scope.row.nominalPowerRatio) < scope.row.nomPowerTo'
                                    placement="top">
                                    <div slot="content">{{ $t('nominal.Power.Ratio.smaller0.big') }}（>
                                        {{ scope.row.nomPowerTo }}%）。<br />{{ $t('nominal.Power.Ratio.smaller1.big') }}<br />{{ $t('nominal.Power.Ratio.smaller2.big') }}<br />
                                        {{ $t('nominal.Power.Ratio.smaller3.big') }}</div>
                                    <i class="el-icon-warning" style="color:orange;font-size:16px;"></i>
                                </el-tooltip>
                                <el-tooltip v-else-if='parseInt(scope.row.nominalPowerRatio) >= scope.row.nomPowerTo'
                                    placement="top">
                                    <div slot="content">{{ $t('nominal.Power.Ratio.smaller0.big150.1') }}
                                        <br>
                                        {{ $t('nominal.Power.Ratio.smaller0.big150.2') }}
                                    </div>
                                    <i class="el-icon-warning" style="color:red;font-size:16px;"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" :label="$t('project.data.PV-peak-power')" width="110">
                            <template slot-scope="scope">
                                {{ scope.row.peakPower == undefined ? '' : scope.row.peakPower + 'kwp' }}
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" prop="d" label="" width="50">
                            <template slot-scope="scope">
                                <a href="javascript:;" @click.stop='tprojectDeleteInverter(scope.row)'>
                                    <i class="el-icon-delete"></i>
                                </a>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card class="margin-top20" v-if='nowTableData.inverterId'>
                <div slot="header" class="clearfix">

                    <span v-if="nowTableData.resultInfoStatus == 4"><i class="el-icon-success"
                            style="color:green;font-size:16px;"></i>{{ $t('new.PV.management.PV-array/Inverter.conditioned') }}</span>
                    <span v-else-if="nowTableData.resultInfoStatus == 5"><i class="el-icon-success"
                            style="color:green;font-size:16px;"></i>{{ $t('new.PV.management.PV-array/Inverter') }}</span>
                    <span v-else-if="nowTableData.resultInfoStatus == 3"><i class="el-icon-warning"
                            style="color:red;font-size:16px;"></i>{{ $t('user.inverter.dangerous') }}</span>
                    <span v-else-if="nowTableData.resultInfoStatus == 2"><i class="el-icon-warning"
                            style="color:red;font-size:16px;"></i>{{ $t('pv.array.dangerous') }}</span>
                    <span v-else-if="nowTableData.resultInfoStatus == 1"><i class="el-icon-warning"
                            style="color:orange;font-size:16px;"></i>{{ $t('user.capacity.loss') }}</span>
                    <span v-else> {{ $t('new.PV.management.PV-array/Inverter.conditioned') }}</span>

                </div>
                <div>
                    <el-table :data="configTableData" max-height="500" :border='false' align='center' winth='100%' stripe>
                        <el-table-column prop="d" :label="$t('new.PV.management.configuration')" fixed width="200">
                            <template slot-scope="scope">
                                {{ scope.row.text }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="d" label="" fixed width="150">
                            <template slot-scope="scope">
                                {{ scope.row.value }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="d" label="" fixed width="190">
                            <template slot-scope="scope">
                                {{ scope.row.text2 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="d" label="" v-for='items in tableDataNoNull.length' :key='items'
                            min-width="150">
                            <template slot="header" slot-scope="scope">
                                {{ $t('new.PV.management.input') }} {{ items }}
                            </template>
                            <template slot-scope="scope">
                                <!-- 光伏阵列-{{item}} -->
                                {{ scope.row['value' + items] }}

                                <span v-if='returnStatusFlag(scope.row)'>
                                    <i class="el-icon-success" style="color:green;font-size:16px;"
                                        v-if='!((returnStatus(scope.row["compatibilityParam" + items], scope.row.text2, "text") || []).length > 0)'></i>

                                    <!-- 红色提醒 -->
                                    <el-tooltip placement="top"
                                        v-else-if='scope.row.text2 == $t("new.PV.management.Max.PV-voltage")'>
                                        <div slot="content"><span
                                                v-for='(item, index) in returnStatus(scope.row["compatibilityParam" + items], scope.row.text2, "text")'
                                                :key='(index + 1) * 100' class="pre-line"> {{ item }} <br></span></div>
                                        <i class="el-icon-warning" style="color:red;font-size:16px;"></i>
                                    </el-tooltip>



                                    <el-tooltip placement="top"
                                        v-else-if='(returnStatus(scope.row["compatibilityParam" + items], scope.row.text2, "text") || []).length > 0 && !(scope.row.text2 == $t("new.PV.management.peak-power-input") && scope.row["compatibilityParam" + items].maxPeakPowerStatus == 2)'>
                                        <div slot="content"><span
                                                v-for='(item, index) in returnStatus(scope.row["compatibilityParam" + items], scope.row.text2, "text")'
                                                :key='index'> {{ item }} <br></span></div>
                                        <i class="el-icon-warning"
                                            :style='!(scope.row.text2 == $t("new.PV.management.peak-power-input") && scope.row["compatibilityParam" + items].maxPeakPowerStatus == 1) ? "color:orange;font-size:16px;" : "color:yellow;font-size:16px;"'></i>
                                    </el-tooltip>
                                </span>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>

        </div>
        <div class="left-sub-box" v-else>
            <inverterScreen v-if="!screenflag && active == 3" :active="active" @backInv='backInv' :firstFlag="firstFlag"
                :IsStorageInv="IsStorageFlag" @screenInverter="screenInverter" :projectData="projectData" :InvId="InvId">
            </inverterScreen>
        </div>
        <div class="right-sub-box">
            <rightReport :po-form-data="poFormData" :data-visible="dataVisible" @clickNext="clickNext"></rightReport>
        </div>
        <inverterDetail :detailData='detailData'></inverterDetail>
    </div>
</template>

<script>
import rightReport from '../rightReport'
import inverterDetail from './inverterDetail'
import baseTable from '../../../components/common/baseTable'
import { setTimeout } from 'timers';
import { Alert } from 'element-ui';
import inverterScreen from './inverterScreen'
export default {
    components: {
        rightReport,
        inverterDetail,
        baseTable,
        inverterScreen
    },
    props: {
        form: {
            type: Object,
            default: () => {
                return {}
            }
        },
        active: {
            type: Number,
            default: 1,
        },
        poFormData: {},
        next: {
            type: Boolean,
            default: false
        },
        projectData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            dataVisible: {
                total: false,
                performance: true,
                overview: true
            },
            inverterList: [],
            firmName: [],
            configTableData: [{
                text: this.$t('project.data.inverter') + '：',
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.PV-array') + '：',
                value1: '光伏阵列-1',
            }, {
                text: this.$t('new.PV.management.independent-inputs'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.number-of-PV-modules'),
                value1: '光伏阵列-1',
            }, {
                text: this.$t('new.PV.management.Max.DC-power'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.peak-power-input'),
                value1: '光伏阵列-1',
            }, {
                text: this.$t('new.PV.management.Min.DC-voltage'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.typical-PV-voltage'),
                value1: '光伏阵列-1',
            }, {
                text: this.$t('project.inv.u-inv-AC-from'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.Min.PV-voltage'),
                value1: '光伏阵列-1',
            }, {
                text: this.$t('Max.DC-operating-voltage'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.Max.PV-voltage'),
                value1: '光伏阵列-1',
            }, {
                text: this.$t('new.PV.management.Max.DC-current'),
                value: 'SK-SU3000C',
                text2: this.$t('new.PV.management.Max.current-of-PV-array'),
                value1: '光伏阵列-1',
            }],
            tableData: [],
            tableDataNoNull: [],
            // column: [{
            //     title: '输入',
            //     value: 'a',
            // }, {
            //     title: '光伏阵列',
            //     value: 'b',
            // }, {
            //     title: '组串',
            //     value: 'c',
            // }, {
            //     title: '',
            //     value: 'c',
            // }, {
            //     title: '光伏模块',
            //     value: 'd',
            // },],
            firmName: [],
            rowIndex: 0,
            nowTableData: {},
            flag: true,
            detailData: {},
            imgUrlBase: require('../../../assets/img/nbq.jpg'),
            imgFlag: true,
            pvArraysInvFlag: true,
            allInverterList: [],
            inverterSelect: true,
            dialogVisible: false,
            checkedPv: [],
            PvRecommendList: [],
            pvRecommends: [],
            inverterDetailVisi: true,
            nomPowerFrom: 100,
            nomPowerFromVisi: true,
            loading: false,
            screenflag: true,
            InvId: "",
            IsStorageFlag: false,
            firstFlag: true,
            isStorage: "",
            noRecommendFlag: false,
            jp_countryID: '',
            flag_regionID: '',
        };
    },
    computed: {
        returnTableWidth() {
            return `width: ${document.body.offsetWidth * 0.68}px;`
        },
        pvArraysInv() {
            return this.$store.state.sysStore.pvArraysInv
        },
        returnInverterNum() {
            return this.nowTableData.inverterNum
        },
        // poFormData(){
        //     return this.$store.state.sysStore.poFormData
        // }
    },
    watch: {
        returnInverterNum: {
            handler(nv, ov) {
                if (nv) {
                    // this.save()
                    this.$emit('changeNext', true)
                }
            },
            immediate: true
        },
        pvArraysInv: {
            handler(nv, ov) {
                if (nv) {
                    this.pvArraysInvFlag = false
                    let that = this
                    setTimeout(() => {
                        that.pvArraysInvFlag = true
                    }, 100)
                    // this.form.pvArrays = nv
                    this.$set(this.form, 'pvArrays', nv)
                }
            }
        },
        active: {
            handler(nv, ov) {
                if (ov != 3 && nv == 3) {
                    if (!this.nowTableData.index && this.form.forms.length) {
                        let nowIndex = sessionStorage.getItem("nowInvIndex") || 0
                        this.nowTableData = this.form.forms[nowIndex]
                    }
                    if (this.nowTableData.inverterNum) {
                        this.tprojectSelectInverter({ invCount: this.nowTableData.inverterNum })
                        // this.showDetailModal(false)
                        // this.save()
                    } else {
                        this.$emit('changeNext', true)
                    }
                    this.$emit('changeNext', true)
                    // this.save()
                    // this.checkedPv=[]
                    this.tableData.forEach((item, index) => {
                        if (typeof item.pvArrayId == 'object') {
                            this.checkedPv.splice(index, 1)
                            return
                        }
                        if (this.checkedPv.includes(item.pvArrayId)) return
                        this.checkedPv.push(item.pvArrayId)
                    })
                }
                if (ov == 3 && nv != 3) {
                    this.save()
                    this.tprojectSave()
                    console.log(this.screenflag, "this.screenflag");
                    if (!this.screenflag) {
                        this.nowTableData = this.form.forms[0]
                    }

                    let proIDAndINv = []
                    this.form.forms.forEach((item) => {
                        proIDAndINv.push(`${item.inverterId}+${item.index}`)
                    })
                    sessionStorage.removeItem("nowInvIndex")
                    // localStorage.setItem(`p${this.$route.query.id}`,proIDAndINv)
                }
            },
            deep: true,
            immediate: true
        },
        imgUrlBase: {
            handler(nv, ov) {
                this.imgFlag = false
                let that = this
                setTimeout(() => {
                    that.imgFlag = true
                }, 500)

            },
            deep: true,
            immediate: true
        },
        tableData: {
            handler(nv, ov) {
                if (nv) {
                    let arr = []
                    let i = 0
                    nv.forEach(item => {
                        if (item) {
                            item['index'] = i
                            arr.push(item)
                            i++
                        }
                    });
                    // this.tableData = arr
                    this.tableDataNoNull = arr
                    this.setLastTableData()

                }
            },
            deep: true
        },
        form: {
            handler(nv, ov) {
                console.log('nv-inverter');
                this.inverterDetailVisi = !this.inverterDetailVisi
                if (this.form.forms.length > 0) {
                    this.screenflag = true
                    this.firstFlag = false
                } else {
                    this.firstFlag = true
                    this.screenflag = false
                    this.IsStorageFlag = true
                }
                let arr = []
                nv.forms.forEach((item) => {
                    this.inverterList.forEach((itemFlag) => {
                        if (item.inverterId == itemFlag.invID) {
                            arr.push(itemFlag)
                        }
                    })
                })
                this.isStorage = arr.some(item => { return item.IsStorage == true })
                if (this.isStorage && this.projectData.batteryRequired) {
                    this.$emit('batteryVisi', 1)
                } else {
                    this.$emit('batteryVisi', 0)
                }
            },
            deep: true,
            immediate: true
        },
        nowTableData: {
            handler(nv, ov) {
                this.$store.commit('setInvFormData', nv)
                if (nv) {
                    // if(nv.inverterId != ov.inverterId){
                    // this.returnImgUrl()
                    // }
                }

                let flag = this.inverterList.some((item) => {
                    return item.invID == this.nowTableData.inverterId
                })
                if (!flag) {
                    this.getInverterListData(1)
                }
                this.nomPowerFrom = nv.nomPowerFrom ? nv.nomPowerFrom : (this.detailData.nomPowerFrom || 100)
            },
            immediate: true,
            deep: true
        },
        'nowTableData.currentStatus': {
            handler() {
                if (this.nowTableData.currentStatus == 1) {
                    this.$message.warning($t('projetc.data.PV-overcurrent'));
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {

    },
    mounted() {
        this.jp_countryID = localStorage.getItem('jp_countryID')
        this.flag_regionID = localStorage.getItem('flag_regionID')
        // this.getInverterListData()
    },
    methods: {
        backInv() {
            let nowIndex = sessionStorage.getItem("nowInvIndex") || 0
            this.nowTableData = this.form.forms[nowIndex] || {}
            this.screenflag = true
        },
        // 每个步骤自动保存
        async tprojectSave() {
            let status = parseInt(localStorage.getItem('statusF'))
            if (status < 3) {
                status = 3
                localStorage.setItem("statusF", 3)
            }
            let res = await this.API.tprojectSave({
                proId: this.$route.query.id,
                status
            })
        },
        indexMethod(index) {
            // return (index+1) +" "+ (this.$t('inverterNumber'))
            return 'MPPT' + " " + (index + 1)
        },
        returnStatusFlag(row) {
            let flag = true
            if (row.text == this.$t('project.data.inverter') + '：' || row.text == this.$t('new.PV.management.independent-inputs') || row.text == this.$t('new.PV.management.Max.DC-power') + '(cosφ=1)：') {
                flag = false
            }
            return flag
        },
        pvArrayChange(row) {
            let obj = {
                pvArrayId: row.pvArrayId,
                invInputId: row.index,
                stringCount: row.stringCount || 1,
                moduleCount: row.moduleCount || 1,

            }
            this.tprojectSelectInverter(obj)
        },
        async returnImgUrl() {
            if (this.nowTableData.pictureUrl) {
                let res = await this.API.systemTuserGetLogo({
                    fileName: this.nowTableData.pictureUrl
                })
                if (res) {
                    this.imgUrlBase = 'data:image/gif;base64,' + res
                } else {
                    this.imgUrlBase = require('../../../assets/img/nbq.jpg')
                }
            } else {
                this.imgUrlBase = require('../../../assets/img/nbq.jpg')
            }
        },
        async tprojectSelectInverter(obj) {
            let arr = Object.keys(obj) || []
            for (let i = 0; i < arr.length; i++) {
                if ((!obj[arr[i]]) & obj[arr[i]] != 0) {
                    return
                }
            }
            let res = await this.API.tprojectSelectInverter(Object.assign({}, {
                projectId: this.$route.query.id,
                invSequence: this.nowTableData.invSequence,
                inverterId: this.nowTableData.inverterId
            }, obj))
            if (res && res.code && res.code == 500) {
                return
            }
            // this.$nextTick(()=>{
            // this.inverterDetailVisi =false 
            this.$emit('noticeUpload', res)
            // })

            // this.form.pvArrays = res.pvArrays
            this.$set(this.form, 'pvArrays', res.pvArrays)
            this.assembleInverterData(res)
            this.returnImgUrl()
        },
        modulCountBlur(row) {
            if (!row.moduleCount) {
                row.moduleCount = 1
            }
        },
        countBlur(row) {
            if (!row.stringCount) {
                row.stringCount = 1
            }
        },
        assembleInverterData(obj) {
            let arr = [];
            Object.keys(obj.iorMap).forEach(item => {
                obj.iorMap[item]['invSequence'] = item
                arr.push(obj.iorMap[item])
            })
            // if(!obj.iorMap){
            //     // this.nowTableData.inverterNum = 1
            //     this.$set(this.nowTableData,'inverterNum',1)
            // }
            // console.log(this.nowTableData.invSequence,'arr');
            // this.$set(this.form.forms,this.nowTableData.invSequence-1,arr[this.nowTableData.invSequence-1]||arr[0])

            this.$nextTick(() => {
                this.tableData = (arr[this.nowTableData.invSequence - 1] || arr[arr.length - 1]).inverterInputs
            })

            this.nowTableData = arr[this.nowTableData.invSequence - 1] || arr[arr.length - 1] || {}
            let i = 0
            obj.pvArrays.forEach(item => {
                if (item) {
                    item['id'] = i
                    this.$set(this.form.pvArrays, i, item)
                }
                i++
            })
        },
        tableRowClassName({ row, rowIndex }) {
            row['index'] = rowIndex
            if (this.flag) {
                this.tableData = row.inverterInputs
                this.nowTableData = row || {}
                this.flag = false
                // setTimeout(()=>{

                // },100)
            }
            if (rowIndex === this.rowIndex) {
                return 'success-row';
            }
            return '';
        },
        //组装兼容数据
        async setLastTableData() {
            let res = await this.API.tinverterGetByInverterId({
                inverterId: this.nowTableData.inverterId
            })
            if (res && res.code && res.code == 500) {
                return
            }
            this.detailData = res || {}

            let data = res.tpvParamList || []
            let arr = data.map(item => item.iInvDCMax)

            // let powerFrom = localStorage.getItem(`p${this.$route.query.id}+${this.detailData.invID}+${this.nowTableData.index}`)
            // console.log(powerFrom,"powerFrom");
            // if (!powerFrom) {
            //     localStorage.setItem(`p${this.$route.query.id}+${this.detailData.invID}+${this.nowTableData.index}`,this.detailData.nomPowerFrom)
            // }
            // this.detailData.nomPowerFrom = powerFrom?powerFrom:this.detailData.nomPowerFrom
            // this.nomPowerFrom = powerFrom?powerFrom:this.detailData.nomPowerFrom

            this.configTableData[0].value = res.name
            this.configTableData[1].value = res.inputCount
            this.configTableData[2].value = (res.pInvDCmax ? res.pInvDCmax / 1000 : '-') + 'kW'
            this.configTableData[3].value = res.uInvPVmin + 'V'
            this.configTableData[4].value = res.uInvACFrom + 'V / ' + res.uInvACTo + 'V'
            this.configTableData[5].value = res.uInvPVmax + 'V'
            this.configTableData[6].value = arr.join('/') + 'A'
            for (let i = 0; i < this.tableDataNoNull.length; i++) {
                // this.configTableData[0][`value${i+1}`] ='光伏阵列-'+ (i+1)
                this.$set(this.configTableData[0], `value${i + 1}`, this.returnModalName(this.tableDataNoNull[i].pvArrayId))
                this.$set(this.configTableData[1], `value${i + 1}`, this.tableDataNoNull[i].moduleCount)
                this.$set(this.configTableData[2], `value${i + 1}`, this.tableDataNoNull[i].peakPower / 1000 + 'kWp')
                this.$set(this.configTableData[3], `value${i + 1}`, this.tableDataNoNull[i].uTypicalPV + 'V')
                this.$set(this.configTableData[4], `value${i + 1}`, this.tableDataNoNull[i].uMinPV + 'V')
                this.$set(this.configTableData[5], `value${i + 1}`, this.tableDataNoNull[i].uMaxPV + 'V')
                this.$set(this.configTableData[6], `value${i + 1}`, this.tableDataNoNull[i].iMaxPVArray + 'A')

                this.$set(this.configTableData[0], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[1], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[2], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[3], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[4], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[5], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
                this.$set(this.configTableData[6], `compatibilityParam${i + 1}`, this.tableDataNoNull[i].compatibilityParam)
            }

        },
        /*逆变器修改*/
        inverterChange(id) {
            let obj = {

            }
            if (id == 'more') {
                this.nowTableData.inverterId = ' '
                this.$nextTick(() => {
                    this.$refs.oldinverter.visible = true
                })
                this.getInverterListData(1)
                return
            }
            this.checkedPv = []

            this.tprojectSelectInverter({ inverterId: this.nowTableData.inverterId })
            // this.showDetailModal()
            // this.tprojectPvRecommend()
            // this.save()
        },
        returnStatus(obj, item, type) {
            let arr = []
            if (obj) {
                let statusName = {}
                statusName[this.$t('new.PV.management.typical-PV-voltage')] = {
                    0: [],
                    1: [this.$t('user.capacity.loss'), this.$t('new.PV.management.2sb-1300tl'), this.$t('max.pv.array.solution.voltage.smaller')]
                }
                statusName[this.$t('new.PV.management.Min.PV-voltage')] = {
                    0: [],
                    1: [this.$t('user.capacity.loss'), this.$t('new.PV.management.2sb-1300tl'), this.$t('max.pv.array.solution.current.bigger')]
                }

                //最大MPPT电压提醒
                statusName[this.$t('new.PV.management.Max.PV-voltage')] = {
                    0: [],
                    1: [this.$t('pv.array.dangerous'), this.$t('max.voltage.bigger.than.pv.array'), this.$t('max.voltage.solution.bigger.than.pv.array')],
                    // 2:[this.$t('user.inverter.dangerous'),this.$t('max.voltage.bigger.than.inverter'),this.$t('nominal.Power.Ratio.normal')]
                    2: [this.$t('MpptDangerMsg')]
                },

                    statusName[this.$t('new.PV.management.Max.current-of-PV-array')] = {
                        0: [],
                        1: [this.$t('user.capacity.loss'), this.$t('max.pv.array.current.bigger'), this.$t('max.pv.array.solution.current.bigger')]
                    }
                statusName[this.$t('new.PV.management.peak-power-input')] = {
                    0: [],
                    1: [this.$t('user.capacity.loss'), this.$t('max.pv.array.current.bigger.2'), this.$t('max.pv.array.current.bigger.3')],
                    2: [1]
                }
                if (item == this.$t('new.PV.management.typical-PV-voltage')) {
                    arr = statusName[this.$t('new.PV.management.typical-PV-voltage')][obj.normalVoltageStatus]
                }
                else if (item == this.$t('new.PV.management.Min.PV-voltage')) {
                    arr = statusName[this.$t('new.PV.management.Min.PV-voltage')][obj.minVoltageStatus]
                }
                else if (item == this.$t('new.PV.management.Max.PV-voltage')) {
                    arr = statusName[this.$t('new.PV.management.Max.PV-voltage')][obj.maxVoltageStatus]
                }
                else if (item == this.$t('new.PV.management.Max.current-of-PV-array')) {
                    arr = statusName[this.$t('new.PV.management.Max.current-of-PV-array')][obj.maxPVCurrentStatus]
                }
                else if (item == this.$t('new.PV.management.peak-power-input')) {
                    arr = statusName[this.$t('new.PV.management.peak-power-input')][obj.maxPeakPowerStatus]
                }
                else {
                    arr = []
                }
            }
            return arr
        },
        returnModalName(id) {
            let name = ''
            this.form.pvArrays.forEach(item => {
                if (item.id == id) {
                    name = item.name
                }
            })
            return name
        },

        /*获取逆变器数据*/
        // queryType 0:默认查询 1:查询更多
        async getInverterListData(queryType = 1) {
            let res = await this.API.systemTinverterGetList({
                regionID:this.flag_regionID,
                countryID:this.jp_countryID,
                projectId: this.$route.query.id,
                queryType
            })

            if (queryType == 0) {
                let more = {
                    invID: 'more',
                    name: "More"
                }
                res.push(more)
            }
            this.inverterList = res
        },
        async tprojectDeleteInverter(row) {
            // console.log(row);
            let res = await this.API.tprojectDeleteInverter({
                invSequence: row.invSequence,
                projectId: this.$route.query.id,
            })
            if (res && res.code && res.code == 500) {
                return
            }
            this.$emit('noticeUpload', res)
            // localStorage.removeItem(`p${this.$route.query.id}+${row.inverterId}+${row.index}`)
            // this.form.forms.forEach((item,index)=>{
            //     console.log(row.index,index);
            //     if (index >= row.index) {
            //         console.log(item.inverterId,"row");
            //         let valueF = localStorage.getItem(`p${this.$route.query.id}+${item.inverterId}+${index+1}`)||100
            //         localStorage.setItem(`p${this.$route.query.id}+${item.inverterId}+${index}`,valueF)
            //         localStorage.removeItem(`p${this.$route.query.id}+${item.inverterId}+${index+1}`)
            //     }
            // })
            if (this.form.forms.length == 0) {
                this.$set(this._data, 'nowTableData', [])
                this.$set(this._data, 'tableData', [])
                this.checkedPv = []
                return
            }

            this.rowIndex = 0
            this.tableData = this.form.forms[0].inverterInputs
            this.$set(this._data, 'nowTableData', this.form.forms[0])
            // 删除后更新pv列表
            this.checkedPv = []
            this.tableData.forEach((item, index) => {
                if (typeof item.pvArrayId == "object") {
                    this.checkedPv.splice(index, 1)
                    return
                }
                if (this.checkedPv.includes(item.pvArrayId)) return
                this.checkedPv.push(item.pvArrayId)
            })
        },
        addInverterData() {
            sessionStorage.setItem("nowInvIndex", this.nowTableData.index)
            this.screenflag = false
            this.InvId = ''
            let arr = []
            this.form.forms.forEach((item) => {
                this.inverterList.forEach((itemFlag) => {
                    if (item.inverterId == itemFlag.invID) {
                        arr.push(itemFlag)
                    }
                })
            })
            console.log(arr, "arr");
            this.isStorage = arr.some(item => { return item.IsStorage == true })
            if (this.isStorage) {
                this.IsStorageFlag = false
            } else {
                this.IsStorageFlag = true
            }
            this.getInverterListData()
            if (this.form.forms.length == 0) {
                // this.screenflag = false
                this.form.forms.push({ invSequence: 1 })
            } else {
                // this.form.forms.push({})

            }
            // this.screenflag = false
            // this.form.forms.push({})
            // this.rowIndex = this.form.forms.length-1
            let index = (this.form.forms[this.form.forms.length - 2] || {}).invSequence || 0

            this.nowTableData = { invSequence: parseInt(index) + 1 }
            // this.screenflag = true
            console.log(this.screenflag, "this.screenflag");
        },
        tableClick(row, column, event) {
            if (!this.nowTableData.inverterId) {
                this.form.forms.pop()
            }
            this.rowIndex = row.index
            this.tableData = row.inverterInputs
            // let newRow = JSON.parse(JSON.stringify(row))
            this.$set(this._data, 'nowTableData', row)
            // 切换后 pv展示对应更新
            this.checkedPv = []
            this.tableData.forEach((item, index) => {
                if (typeof item.pvArrayId == 'object') {
                    this.checkedPv.splice(index, 1)
                    return
                }
                if (this.checkedPv.includes(item.pvArrayId)) return
                this.checkedPv.push(item.pvArrayId)
            })
        },
        async showDetailModal(flag) {
            if (flag) {
                this.$store.commit('setVisibleInfo', true)
            }

            let res = await this.API.tinverterGetByInverterId({
                inverterId: this.nowTableData.inverterId,
            })
            if (res && res.code && res.code == 500) {
                return
            }
            this.detailData = res || {}
            // let powerFrom = localStorage.getItem(`p${this.$route.query.id}+${this.detailData.invID}+${this.nowTableData.index}`)
            // if (!powerFrom) {
            //     localStorage.setItem(`p${this.$route.query.id}+${this.detailData.invID}+${this.nowTableData.index}`,this.detailData.nomPowerFrom)
            // }
            // this.detailData.nomPowerFrom = powerFrom?powerFrom:this.detailData.nomPowerFrom
            // this.nomPowerFrom = powerFrom?powerFrom:this.detailData.nomPowerFrom
            // this.tprojectPvRecommend()
        },


        inverterNumChange() {
            this.save()
            this.tprojectSelectInverter({ invCount: this.nowTableData.inverterNum })
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    // this.$emit('noticeUpload',true)
                    // this.$emit('closeAll',true)
                    this.$emit('changeNext', true)
                } else {
                    this.$emit('closeAll', false)
                    return false;
                }
            });
        },
        // 推荐内容
        async tprojectPvRecommend() {
            this.loading = true
            let pvForRecommend = this.checkedPv.join()
            let nomPowerFrom = this.nomPowerFrom || this.nowTableData.nomPowerFrom || this.detailData.nomPowerFrom || 100
            let res = await this.API.tprojectPvRecommend({
                inverterId: this.nowTableData.inverterId,
                projectId: this.$route.query.id,
                nomPowerFrom,
                nomPowerTo: this.detailData.nomPowerTo || this.nowTableData.nomPowerTo,
                invSequence: this.nowTableData.invSequence,
                pvForRecommend
            })
            this.PvRecommendList = res.pvRecommends || []
            this.loading = false
            if (!this.PvRecommendList.length) {
                this.noRecommendFlag = true
            } else {
                this.noRecommendFlag = false
            }
        },

        // 选择pv阵列接口
        async tprojectSelectPvForRecommend(pvOrder, clickType) {
            let res = await this.API.tprojectSelectPvForRecommend({
                projectId: this.$route.query.id,
                invSeq: this.nowTableData.invSequence,
                pvOrder,
                clickType
            })
            // this.$nextTick(()=>{

            this.$emit('noticeUpload', res)
            //  this.inverterDetailVisi =  !this.inverterDetailVisi
            // })

        },
        // 选择推荐接口
        async tprojectSelectPvRecommand(inverterInputs) {
            let res = await this.API.tprojectSelectPvRecommand({
                projectId: this.$route.query.id,
                invSeq: this.nowTableData.invSequence,
                inverterInputs
            })
            // this.setLastTableData()
            // this.inverterDetailVisi =false 
            this.$emit('noticeUpload', res)
            this.$set(this.form, 'pvArrays', res.pvArrays)
            this.assembleInverterData(res)
            // this.pvRecommends =res.pvRecommends
        },
        // 点击next
        clickNext() {
            if (!this.screenflag) {
                return
            }
            let pvselectFlag = false
            this.form.forms.forEach((item) => {
                if (item.inverterInputs.length == 0 || item.nominalPowerRatio == '0%') {
                    pvselectFlag = true
                }
            })
            if (pvselectFlag) {
                this.$message.error(this.$t('Inverter-selectAllPv'));
                return
            }
            let arr = []
            this.form.forms.forEach((item) => {
                this.inverterList.forEach((itemFlag) => {
                    if (item.inverterId == itemFlag.invID) {
                        arr.push(itemFlag)
                    }
                })
            })
            this.isStorage = arr.some(item => { return item.IsStorage == true })
            if (!this.nowTableData.invSequence && this.next) {
                if (this.projectData.batteryRequired != 0 && this.isStorage) {
                    this.$emit('nextMenus', this.active + 1)
                    this.$emit('changeClick')
                    console.log(1);
                    return
                }
                console.log(2);
                this.$emit('nextMenus', this.active + 2)
                this.$emit('changeClick')
            } else {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.projectData.batteryRequired != 0 && this.isStorage) {
                            console.log(3);
                            this.$emit('nextMenus', this.active + 1)
                            this.$emit('changeClick')
                            return
                        }
                        console.log(4);
                        this.$emit('nextMenus', this.active + 2)
                        this.$emit('changeClick')
                    } else {
                        this.$emit('closeAll', false)
                        return false;
                    }
                });
            }
        },

        // 点击选择pv模块事件
        changePvModule(nv, index) {
            let clickType = nv ? 1 : 0
            this.tprojectSelectPvForRecommend(index, clickType)
            if (this.checkedPv.length != 0) {
                this.tprojectPvRecommend()
            } else {
                this.PvRecommendList = []
            }
        },
        // 点击选择推荐事件
        selectCard(data) {
            this.dialogVisible = false
            let inverterInputs = []
            data.pvList.pvRecommends.forEach((item, index) => {
                let obj = {}
                obj.pvArrayId = item.pvArray.id
                obj.stringCount = item.stringcount
                obj.moduleCount = item.moduleNum
                inverterInputs.push(obj)
            })
            this.tprojectSelectPvRecommand(inverterInputs)
        },
        computeBtn() {
            // if (this.checkedPv.length == 0) {
            //     this.$message.error(this.$t('select-PV-module'));
            // }else {
            // this.showDetailModal(false)
            // this.tprojectPvRecommend()
            this.dialogVisible = true
            if (this.checkedPv.length > 0) {
                this.tprojectPvRecommend()
            } else {
                this.PvRecommendList = []
            }
            // }
        },
        changeNomPowerFrom(num) {
            if (num == 1) {
                this.nomPowerFromVisi = false
                this.$nextTick(() => {
                    this.$refs.nomPowerFrom1.focus()
                })

                console.log(this.$refs);
            } else if (num == 2) {
                this.nomPowerFromVisi = true
                // this.detailData.nomPowerFrom = this.nomPowerFrom
                if (this.nomPowerFrom > this.nowTableData.nomPowerTo) {
                    this.nowTableData.nomPowerFrom = this.detailData.nomPowerFrom
                    this.nomPowerFrom = this.nowTableData.nomPowerFrom
                } else {
                    this.nowTableData.nomPowerFrom = this.nomPowerFrom
                }
                this.changeRatio(this.nomPowerFrom)
                // localStorage.setItem(`p${this.$route.query.id}+${this.detailData.invID}+${this.nowTableData.index}`,this.nomPowerFrom)
            }
        },
        async changeRatio(nomPowerFrom) {
            let res = await this.API.changeRatio({
                projectId: this.$route.query.id,
                invSequence: this.nowTableData.invSequence,
                invSequence: this.nowTableData.invSequence,
                nomPowerFrom,
                nomPowerTo: this.nowTableData.nomPowerTo
            })
            this.$emit('noticeUpload', res)
        },
        updateView(value) {
            this.nowTableData.inverterNum = value.replace(/[^(0-9)(\.)]/g, '')
            this.$forceUpdate()
        },
        // 筛选后选择逆变器
        screenInverter(item) {

            if (item.isModify) {
                this.nowTableData.inverterId = item.invID
                // sessionStorage.setItem("IsStorage"+item.invID,item.IsStorage)
                this.inverterChange()
                // localStorage.removeItem(`p${this.$route.query.id}+${this.nowTableData.inverterId}+${this.nowTableData.index}`)
            } else {
                // this.addInverterData()
                this.getInverterListData()
                if (this.form.forms.length == 0) {
                    this.form.forms.push({ invSequence: 1 })
                } else {
                    this.form.forms.push({})
                }
                // this.form.forms.push({})
                this.rowIndex = this.form.forms.length - 1
                let index = (this.form.forms[this.form.forms.length - 2] || {}).invSequence || 0

                this.nowTableData = { invSequence: parseInt(index) + 1 }
                console.log(this.rowIndex, index, this.nowTableData, "nowTableData");
                this.tprojectSelectInverter({ inverterId: item.invID })
            }
            this.screenflag = true
            console.log(item, "item");

        },

        // 修改逆变器
        changeInv(data) {
            // sessionStorage.setItem("addInv",1)
            sessionStorage.setItem("nowInvIndex", this.nowTableData.index)
            this.screenflag = false
            this.InvId = data.inverterId
            this.inverterList.forEach((item) => {
                if (item.invID == data.inverterId) {
                    // this.IsStorageFlag = item.IsStorage
                    if (!this.isStorage || item.IsStorage) {
                        this.IsStorageFlag = true
                    } else {
                        this.IsStorageFlag = false
                    }
                }
            })
        }
    },
};
</script>

<style lang="less">
.inverterData-main-box {
    display: flex;

    .el-card__header {
        background-color: #ddd;
        cursor: pointer;
    }

    .left-sub-box {
        flex: 9;
        width: 75%;
        padding: 10px;

        .box-card {
            .el-card__header:hover {
                color: rgb(44, 154, 197);
            }
        }

        // .el-table__body tbody tr:nth-child(2n+1){
        //     background-color: #eee;
        // }
    }

    .right-sub-box {
        flex: 3;
        width: 25%;
        padding: 10px;
    }

    .img-and-table-box {
        display: flex;

        .img-box {
            flex: 1;
            width: 20%;

            div {
                text-align: center;
                margin-top: 20px;
                cursor: pointer;
            }
        }

        .table-box {
            flex: 7;
            width: 20%;
            position: relative;

            .selectPvModule {
                //    margin-left: 35px;
                display: flex;
                justify-content: center;

                .selectPvTip {
                    color: #606266;
                    font-size: 14px;
                    margin: 20px 15px 20px 0
                }
            }
        }
    }

    .Overbalance {
        display: flex;
        justify-content: space-between;
        color: #999;
        margin-top: 10px;
    }

    .computeBtn {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        color: #fea429;
        font-size: 16px;
        margin-right: 40px;
        text-align: center;
        height: 32px;
        cursor: pointer;
    }

    .el-table::before {
        background-color: #fff;
    }

    .el-table td {
        border-bottom: none;
        text-align: center;
    }

    .el-table th.is-leaf {
        border-bottom: none;
        text-align: center;
    }

    .margin-top20 {
        margin-top: 20px;
    }

    .green-color {
        color: green;
    }

    .red-color {
        color: red;
    }

    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: rgb(255, 219, 168);
    }

    .recommend {
        display: flex;
        // justify-content: space-around;
        flex-wrap: wrap;
        // display: inline-block;
    }

    .recommendCard {
        width: 32%;
        // height: 100px;
        margin: 10px 6px;

        .tip {
            text-align: center;
        }
    }
}
</style>

<style lang="less" scoped>
::v-deep .recommendCard .el-card__body {
    padding: 20px 0;
}</style>